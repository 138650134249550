import React from "react";
import {Container, Paper, Grid2 as Grid, List, ListItem, ListItemText, Typography} from "@mui/material";
import {UserInvitationListItem} from "./UserInvitationListItem";
import {UserInvitationState} from "../../data-types";
import {useSearchParams} from "react-router-dom";
import {EnrollCard, NoDataCard} from "../../components";
import qs from "qs";

interface InvitationSectionProps {
    invitations: UserInvitationState;
    initAccept: (competitionId: number) => void;
    reject: (competitionId: number) => void;
}

export function InvitationSection({invitations, initAccept, reject}: InvitationSectionProps) {
    const [searchParams] = useSearchParams();
    const eventInParams = qs.parse(searchParams.toString(), {ignoreQueryPrefix: true}).eventId;

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}}>
                <Typography variant="h6" sx={{mb: 2}}>
                    Pending
                </Typography>
                    {invitations.current.length === 0 ?
                        <NoDataCard message="You have no currently pending invitations." /> :
                        null
                    }
                    {invitations.current.map((c, i) =>
                        <Paper
                            component={Container}
                            elevation={0}
                            sx={{
                                mb: 4,
                                border: c.competitionId === parseInt(eventInParams as string) ? "1px solid #28871D" : "none"
                            }}
                        >
                            <EnrollCard
                                key={i}
                                event={c}
                                initEnroll={initAccept}
                                reject={reject}
                            />
                        </Paper>
                    )}
            </Grid>
            <Grid size={{xs: 12}}>
                <Typography variant="h6">
                    Past
                </Typography>
                <List>
                    {invitations.past.length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="Nothing here yet."
                            />
                        </ListItem> :
                        null
                    }
                    {invitations.past.map((c, i) =>
                        <UserInvitationListItem
                            key={i}
                            invitation={c}
                            initAccept={initAccept}
                            reject={reject}
                            highlight={false}
                        />
                    )}
                </List>
            </Grid>
        </Grid>
    )
}