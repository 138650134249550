import React from "react";
import {VideoJsPlayerOptions} from "video.js";
import {Button, Grid2 as Grid, Paper, Typography} from "@mui/material";
import {VideoPlayer} from "../../components";
import {Link} from "react-router-dom";
import PollIcon from "@mui/icons-material/Poll";
import ReportIcon from '@mui/icons-material/Report';
import {ResponseCardProps} from "../../data-types";

export function ResponseCard(props: ResponseCardProps) {

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: props.sources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    const unhandledFlag = props.flagged && !props.flagHandled;
    const displayWarning = props.numberReports > 0 || unhandledFlag;

    return (
        <Paper elevation={1}>
            <Grid container size={{xs: 12}} justifyContent="center" alignItems="center" sx={displayWarning ? {mb: 5, border: 1, borderColor: "secondary.main"} : {mb: 5}}>
                <Grid size={{xs: 12, sm: 3, md: 3}}>
                    <VideoPlayer
                        options={videoOptions}
                        height={175}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 6}} sx={{p: 2}}>
                    <Typography variant="h6" sx={{mb: 2}}>
                        {props.username}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`Average Score:  ${props.averageRawScore ? props.averageRawScore.toFixed(2) : "N/A"}`}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`Reviews Completed:  ${props.numberReviews}`}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`Unhandled Reports: ${props.numberReports}`}
                    </Typography>
                </Grid>
                <Grid container spacing={2} size={{xs: 12, sm: 3, md: 3}} sx={{p: 2, textAlign: 'center'}}>
                    <Grid size={{xs: 12}}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                fullWidth
                                component={Link}
                                to={`/admin/response/detail/${props.responseId}`}
                                startIcon={<PollIcon />}
                            >
                                See Reviews
                            </Button>
                    </Grid>
                        {props.numberReports > 0 ?
                            <Grid size={{xs: 12}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    size="small"
                                    component={Link}
                                    to={`/admin/report/detail/${props.responseId}`}
                                    startIcon={<ReportIcon />}
                                >
                                    View Reports
                                </Button>
                            </Grid> :
                            null
                        }
                        {unhandledFlag ?
                            <Grid size={{xs: 12}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    component={Link}
                                    to={`/admin/moderation/detail/${props.responseId}`}
                                    startIcon={<ReportIcon />}
                                >
                                    Handle Moderation
                                </Button>
                            </Grid> :
                            null
                        }
                </Grid>
            </Grid>
        </Paper>
    )
}