import React, {useState, useEffect, useCallback, useContext} from "react";
import {DataAccess, DataValidation, formatError, UrlConfig} from "../../util";
import {
    InvitationStatus,
    OpenCompetitionResult, OptionalScriptStepConfig,
    RequestStatus,
    UserInvitationState, UserProfileState
} from "../../data-types";
import {parseISO, isAfter, isValid, subHours, formatDistanceToNow} from "date-fns";
import {useSearchParams} from "react-router-dom";
import {MarketingContext, UserContext} from "../../context";
import {toast} from "react-toastify";

type EnrollTab = "invitations" | "available";
type EnrollState = "start" | "legal" | "institutionSelect" | "profileEdit" | "profileEditResident";

export const useEnroll = () => {

    const {user, setUser} = useContext(UserContext);
    const [, setSearchParams] = useSearchParams();
    const {marketingId} = useContext(MarketingContext);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [invitations, setInvitations] = useState<UserInvitationState>({
        current: [],
        past: []
    });
    const [openCompetitions, setOpenCompetitions] = useState<OpenCompetitionResult[]>([]);
    const [enrollTab, setEnrollTab] = useState<EnrollTab>("invitations");

    // for handling enrollment flow
    const [enrollState, setEnrollState] = useState<EnrollState>("start");
    const [selectedCompetition, setSelectedCompetition] = useState<number>(NaN);
    const [legalAgreementUuid, setLegalAgreementUuid] = useState<string | null>(null);
    const [selectedInstitution, setSelectedInstitution] = useState<number | null>(NaN);
    const [optionalScriptStepOptions, setOptionScriptStepOptions] = useState<OptionalScriptStepConfig[]>([]);
    const [goToResponsesCompetitionId, setGoToResponsesCompetitionId] = useState<number | null>(null);

    useEffect(() => {

        const invitationController = new AbortController();
        const openCompetitionController = new AbortController();

        const getInvitations = async () => {
            return await DataAccess.get("/api/user/getInvitations.json", {signal: invitationController.signal}) as UserInvitationState;
        }

        const getOpenEnroll = async () => {
            const r = await DataAccess.get("/api/competition/getOpenCompetitions.json", {signal: openCompetitionController.signal});
            const competitions: OpenCompetitionResult[] = r.competitions;
            competitions.sort((i, j) => {
                const iFirstRoundEnd = parseISO(i.firstRoundResponseEnd + "Z");
                const jFirstRoundEnd = parseISO(j.firstRoundResponseEnd + "Z");
                // if i not valid, order j first
                if (!isValid(iFirstRoundEnd)) {
                    return 1;
                } else if (!isValid(jFirstRoundEnd)) {
                    return -1;
                } else {
                    return isAfter(jFirstRoundEnd, iFirstRoundEnd) ? -1 : 1;
                }
            })
            return competitions;
        }

        const getAll = async () => {
            return await Promise.all([getInvitations(), getOpenEnroll()]);
        }

        getAll()
            .then(([invitations, openEnrollCompetitions]) => {
                setInvitations(invitations);
                setOpenCompetitions(openEnrollCompetitions);
                if (invitations.current.length === 0) {
                    setEnrollTab("available");
                }
            })
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            invitationController.abort();
            openCompetitionController.abort();
        }
    }, []);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
        setEnrollTab(newValue as EnrollTab);
    }, []);

    const initEnroll = (competitionId: number) => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === competitionId);
        if (!found) {
            toast.error("There was a problem, please try again later.");
            return;
        }

        // if payment is non-zero, does response phase end within 3 hours?  If so, disallow enrollment
        const foundPayment = found.paymentParticipant;
        const firstRoundEnd = found.firstRoundResponseEnd;
        if (foundPayment > 0 && firstRoundEnd !== null) {
            const firstRoundEndDate = parseISO(firstRoundEnd + "Z");
            if (isAfter(new Date(), subHours(firstRoundEndDate, 3))) {
                toast.error("You cannot enroll in this event within 3 hours of responses being due.");
                return;
            } else if (isAfter(new Date(), subHours(firstRoundEndDate, 24))) {
                toast.warning(`Please note:  Responses are due in ${formatDistanceToNow(firstRoundEndDate)}.  You will be ineligible for a refund if you enroll.`, {position: "bottom-left"});
            }
        }

        // first check if it requires profile and if profile complete
        if (found.requiresProfile && user && DataValidation.isUserProfileIncomplete(user)) {
            setEnrollState("profileEdit");
            setSelectedCompetition(competitionId);
            return;
        }

        // also handle case where only requires us resident.  Only display that field if null
        if (found.requiresUsResident && user && user.usResident === null) {
            setEnrollState("profileEditResident");
            setSelectedCompetition(competitionId);
            return;
        }

        // event found, does it have a legal agreement?
        if (found.legalAgreementUuid) {
            // yes, open the legal modal
            setEnrollState("legal");
            setLegalAgreementUuid(found.legalAgreementUuid);
            setSelectedCompetition(competitionId);
        } else if (found.isSingleInstSelection) {
            // open the optional step modal
            setEnrollState("institutionSelect");
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setSelectedCompetition(competitionId);
        } else {
            // neither legal agreement, nor single inst.  Attempt submission

            handleEnroll(competitionId, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        // only toast for users that weren't redirected to payment
                        toast.success(`You have enrolled in the event.`)
                        // only present modal for users that weren't redirected to payment
                        // AND for events where round has started
                        if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                            setGoToResponsesCompetitionId(competitionId);
                        }
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                });
        }
    }

    const handleCancel = () => {
        setSelectedCompetition(NaN);
        setSelectedInstitution(NaN);
        setLegalAgreementUuid(null);
        setOptionScriptStepOptions([]);
        setEnrollState("start");
    }

    const handleInstitutionSelect = (id: number | null) => {
        setSelectedInstitution(id);
    }

    const handleCloseGoToResponses = () => {
        setGoToResponsesCompetitionId(null);
    }

    const legalTransitionHandler = () => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;
        // does this event have single inst selection?
        if (found.isSingleInstSelection) {
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setEnrollState("institutionSelect");
        } else {
            handleEnroll(selectedCompetition, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        toast.success(`You have enrolled in the event.`);
                    }
                    // only present modal for users that weren't redirected to payment
                    // AND for events where round has started
                    if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                        setGoToResponsesCompetitionId(selectedCompetition);
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                })
                .finally(handleCancel)
        }
    }

    const optionalStepTransitionHandler = () => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;
        handleEnroll(selectedCompetition, selectedInstitution)
            .then(_ => {
                if (foundPayment === 0) {
                    toast.success(`You have enrolled in the event.`);
                }
                // only present modal for users that weren't redirected to payment
                // AND for events where round has started
                if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                    setGoToResponsesCompetitionId(selectedCompetition);
                }
            })
            .catch(e => {
                toast.error(formatError(e));
            })
            .finally(handleCancel)
    }

    const handleSaveProfile = (u: UserProfileState) => {
        setUser(u);
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;

        // event found, does it have a legal agreement?
        if (found.legalAgreementUuid) {
            // yes, open the legal modal
            setEnrollState("legal");
            setLegalAgreementUuid(found.legalAgreementUuid);
            setSelectedCompetition(selectedCompetition);
        } else if (found.isSingleInstSelection) {
            // open the optional step modal
            setEnrollState("institutionSelect");
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setSelectedCompetition(selectedCompetition);
        } else {
            // neither legal agreement, nor single inst.  Attempt submission
            handleEnroll(selectedCompetition, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        // only toast for users that weren't redirected to payment
                        toast.success(`You have enrolled in the event.`)
                        // only present modal for users that weren't redirected to payment
                        // AND for events where round has started
                        if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                            setGoToResponsesCompetitionId(selectedCompetition);
                        }
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                })
                .finally(handleCancel);
        }
    }

    const handleEnroll = useCallback(async (competitionId: number, chosenInstitutionId: number | null) => {
        const foundInvitation = invitations.current.find(c => c.competitionId === competitionId);
        const foundOpenEnroll = openCompetitions.find(c => c.competitionId === competitionId);
        // const marketingId = qs.parse(searchParams.toString(), {ignoreQueryPrefix: true}).marketingId;
        if (foundInvitation) {
            const invitationPayload = {
                invitationUpdate: {
                    competitionId: competitionId,
                    status: "accepted" as InvitationStatus,
                    chosenInstitutionId: chosenInstitutionId,
                    marketingId: marketingId
                }
            }

            const r = await DataAccess.put("/api/invitation/publish.json", {data: invitationPayload});

            if (foundInvitation.paymentParticipant && r) {
                window.location.href = UrlConfig.getPaymentUrl() + r.PaymentSession.token;
                return;
            }

        } else if (foundOpenEnroll) {
            const openEnrollPayload = {
                enrollmentUpdate: {
                    competitionId: competitionId,
                    isEnrolled: true,
                    chosenInstitutionId: chosenInstitutionId,
                    marketingId: marketingId
                }
            }

            const r = await DataAccess.post(`/api/competition/openEnroll.json`, {data: openEnrollPayload});

            if (foundOpenEnroll.paymentParticipant && r) {
                window.location.href = UrlConfig.getPaymentUrl() + r.PaymentSession.token;
                return;
            }

        } else {
            return;
        }

        // update local state
        setOpenCompetitions(prev => prev.map(c => {
            return (c.competitionId === competitionId) ?
                {...c, isEnrolled: true} :
                c
        }));

        setInvitations(prev => {
            return {
                current: prev.current.filter(c => c.competitionId !== competitionId),
                past: foundInvitation ? [...prev.past, {...foundInvitation, status: "accepted"}] : prev.past
            }
        });

        setSearchParams("");

    }, [invitations, openCompetitions, setSearchParams, marketingId]);

    const handleUnenroll = useCallback(async (competitionId: number) => {
        const data = {
            enrollmentUpdate: {
                competitionId: competitionId,
                isEnrolled: false,
                chosenInstitutionId: null,
                marketingId: null
            }
        };

        await DataAccess.post(`/api/competition/openEnroll.json`, {data: data});
    }, []);

    const rejectInvitation = useCallback(async (competitionId: number) => {
        const data = {
            invitationUpdate: {
                competitionId: competitionId,
                status: "rejected" as InvitationStatus,
                chosenInstitutionId: null,
                marketingId: null
            }
        };

        await DataAccess.put("/api/invitation/publish.json", {data: data});

        // set local state
        setInvitations(prev => {
            const foundInvitation = prev.current.find(c => c.competitionId === competitionId);
            return {
                current: prev.current.filter(c => c.competitionId !== competitionId),
                past: foundInvitation ? [...prev.past, {...foundInvitation, status: "rejected"}] : prev.past
            }
        });
        setOpenCompetitions(prev => prev.filter(c => c.competitionId !== competitionId));

    }, []);

    return {
        requestStatus: requestStatus,
        invitations: invitations,
        openCompetitions: openCompetitions,
        enrollTab: enrollTab,
        handleTabChange: handleTabChange,
        handleEnroll: handleEnroll,
        handleUnenroll: handleUnenroll,
        rejectInvitation: rejectInvitation,
        selectedCompetition: selectedCompetition,
        selectedInstitution: selectedInstitution,
        enrollState: enrollState,
        optionalScriptStepOptions: optionalScriptStepOptions,
        initEnroll: initEnroll,
        legalTransitionHandler: legalTransitionHandler,
        optionalStepTransitionHandler: optionalStepTransitionHandler,
        handleSaveProfile: handleSaveProfile,
        handleCloseGoToResponses: handleCloseGoToResponses,
        handleInstitutionSelect: handleInstitutionSelect,
        handleCancel: handleCancel,
        goToResponsesCompetitionId: goToResponsesCompetitionId,
        legalAgreementUuid: legalAgreementUuid
    }
}