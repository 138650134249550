import React, {useState} from "react";
import {Grid2 as Grid, Typography, Button, Paper, Box, Tooltip} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import WarningIcon from '@mui/icons-material/Warning';
import {TutorialModal} from "../../components";
import ResponseTutorialVideo from "../../video/response_tutorial.mp4";

interface VideoResponseMeta {
    responseId: number;
    saved: boolean;
    flagged: boolean;
    institutionName: string | null;
}

interface ResponseMetaProps {
    selectedResponse: number;
    responseMeta: VideoResponseMeta[];
    handleSwitchPrompt: (responseId: number) => void;
    disableAll: boolean;
    resetRecording: () => void;
    submit: () => void;
}

export function ResponseMeta({selectedResponse, responseMeta, handleSwitchPrompt, disableAll, submit, resetRecording}: ResponseMetaProps) {

    const [tutorialOpen, setTutorialOpen] = useState<boolean>(false);
    const canSubmit = responseMeta.every(c => c.saved);

    return (
        <>
            <Grid container spacing={2} sx={{mb: 5}} alignItems="center" justifyContent="space-between">
                <Grid size={{xs: 4}}>
                    <Typography variant="h4">
                        Prompts
                    </Typography>
                </Grid>
                <Grid size={{xs: 4}} container spacing={2} justifyContent="flex-end">
                    <Grid>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setTutorialOpen(true)}
                            startIcon={<PlayCircleIcon />}
                        >
                            Tutorial
                        </Button>
                    </Grid>
                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            disabled={!canSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 5}} alignItems="center">
                {responseMeta.map((c, i) =>
                    <Grid
                        key={i}
                    >
                        <Tooltip
                            title={c.flagged ?
                                "This video has been flagged by our systems for content which does not meet our terms of use.  Please re-record your video." :
                                ""
                            }
                        >
                            <Paper
                                elevation={1}
                                component={Button}
                                onClick={() => {
                                    handleSwitchPrompt(c.responseId);
                                    resetRecording();
                                }}
                                sx={c.responseId === selectedResponse ? {border: "2px solid green", p: 1} : {border: "2px solid rgba(0, 0, 0, 0.0)", p: 1}}
                                disabled={disableAll}
                            >
                                <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{p: 1}}>
                                    <Grid>
                                        <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                            <Typography variant="h6">
                                                {i + 1}
                                            </Typography>
                                            {c.flagged ?
                                                <WarningIcon color="warning" fontSize="small"  sx={{ml: 3}} /> :
                                                c.saved ?
                                                <CheckCircleIcon color="primary" fontSize="small"  sx={{ml: 3}} /> :
                                                null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <TutorialModal
                open={tutorialOpen}
                setOpen={setTutorialOpen}
                video={ResponseTutorialVideo}
                title={"How-to:  Response Phase"}
            >
                <Typography variant="subtitle2" sx={{mb: 2, mt: 5}}>
                    During the Response Phase, you will respond to one or more prompts with video.
                </Typography>
                <Typography variant="subtitle2" sx={{mb: 5}}>
                    Please see the included video for more instruction.
                </Typography>
            </TutorialModal>
        </>
    )
}