import React, {useState} from "react";
import {Grid2 as Grid, Tooltip, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, Container, ClickAwayListener, IconButton, Button,
    Box} from "@mui/material";
import {RankIcon} from "../../icons";
import {Competition, RequestStatus} from "../../data-types";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Link} from "react-router-dom";
import {LoadingSpinner} from "../../components";

interface RankingSummaryProps {
    competitions: Competition[];
    requestStatus: RequestStatus;
}
export function RankingSummary({competitions, requestStatus}: RankingSummaryProps) {

    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const countCompetitionsWithRankings = competitions.reduce((p, c) => {return p + (c.userStatistics === null ? 0 : 1)}, 0);

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230}}>
            <Grid container alignItems="center" justifyContent="space-between" sx={{pt: 2, px: 2}}>
                <Grid>
                    <Typography variant="h4" color="primary">
                        My Rankings
                    </Typography>
                </Grid>
                <Grid sx={{textAlign: "right"}}>
                    <Button
                        color="darkPrimary"
                        component={Link}
                        to="/myResults"
                    >
                        View All Rankings
                    </Button>
                </Grid>
            </Grid>
            {requestStatus === "loading" ?
                <LoadingSpinner /> :
                (requestStatus === "error") ?
                    null :
                    countCompetitionsWithRankings === 0 ?
                        <Grid container sx={{p: 2}}>
                            <Grid size={{xs: 12}}>
                                <Typography variant="subtitle2">
                                    No rankings to display yet.
                                </Typography>
                            </Grid>
                        </Grid> :
                        <TableContainer sx={{maxWidth: 700, maxHeight: 400, overflowY: "auto"}}>
                            <Table >
                                <TableHead sx={{}}>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography variant="h6" sx={{fontWeight: "bold"}}>
                                                Event
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <Typography variant="h6" sx={{fontWeight: "bold"}}>
                                                    Rank
                                                </Typography>
                                                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                                    <Tooltip
                                                        PopperProps={{disablePortal: true}}
                                                        onClose={() => setOpenTooltip(false)}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        placement="top-end"
                                                        title={<>
                                                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                                                At the end of each round, your scores are compiled into a Composite Score and you are assigned a ranking.  Your ranking corresponds to your Score Percentile.
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.981} />
                                                                    <Typography variant="subtitle2">
                                                                        98th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.961} />
                                                                    <Typography variant="subtitle2">
                                                                        96th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.941} />
                                                                    <Typography variant="subtitle2">
                                                                        94th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.921} />
                                                                    <Typography variant="subtitle2">
                                                                        92th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.901} />
                                                                    <Typography variant="subtitle2">
                                                                        90th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.801} />
                                                                    <Typography variant="subtitle2">
                                                                        80th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.701} />
                                                                    <Typography variant="subtitle2">
                                                                        70th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.601} />
                                                                    <Typography variant="subtitle2">
                                                                        60th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.501} />
                                                                    <Typography variant="subtitle2">
                                                                        50th percentile
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                                                    <RankIcon rank={0.1} />
                                                                    <Typography variant="subtitle2">
                                                                        &lt; 50th percentile
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                    >
                                                        <IconButton onClick={() => setOpenTooltip(true)} size="small" aria-label="rankings help">
                                                            <HelpOutlineIcon color="disabled" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ClickAwayListener>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {competitions.map((c, i) => {
                                        if (c.userStatistics) {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align="left">
                                                        <Typography
                                                            variant="subtitle2"
                                                            component={Link}
                                                            to={`/myResults/${c.competitionMeta!.competitionId}/rankings`}
                                                            sx={{color: "black"}}
                                                        >
                                                            {c.competitionMeta!.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RankIcon rank={c.userStatistics.percentile} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
            }
        </Paper>
    )
}
