import React from "react";
import {LabeledIconProps} from "./LabeledIconProps";
import {SvgIcon} from "@mui/material";

export function MovieLabeledIcon({label, accessibilityText}: LabeledIconProps) {
    return (
        <SvgIcon
            fontSize="large"
            titleAccess={accessibilityText || `video-${label}`}
            aria-label={accessibilityText || `video-${label}`}
        >
            <path
                d="m18 4 2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4z"
            />
            <text
                fill="white"
                x="50%"
                y="63%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="10"
                fontWeight="bold"
            >
                {label}
            </text>
        </SvgIcon>
    )
}