import React from "react";
import {CompetitionCardProps} from "../../data-types";
import {
    Box, Grid2 as Grid, Typography, Button,
    Paper, Chip, Tooltip, Divider, LinearProgress
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import {parseISO, formatDistance, isAfter, format, subHours, addDays} from "date-fns";
import {Link} from "react-router-dom";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {TypographyInlineLinks} from "../../components";

export function ActiveEventCard({competitionMeta, roundStatus, participantStatus, handleSelectRoundModalOpen, handleUnenrollClick}: CompetitionCardProps) {
    let now = new Date();
    let phaseTextTop, phaseTextBottom, actionButton;
    let showWarningIcon: boolean = false;
    let submittedValue: number = 0, requiredValue: number = 1;

    if (roundStatus) {
        // ensure these aren't zero
        submittedValue = roundStatus.todo?.submitted || 0;
        requiredValue = roundStatus.todo?.required || 1;

        if (roundStatus.optionalScriptSteps && roundStatus.optionalScriptSteps.length !== 0) {
            // have to open the modal and select institutions
            phaseTextTop = "Please choose which institutions you want to respond to";
            phaseTextBottom = `Due:  ${format(parseISO(roundStatus.responseEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.responseEndDate + "Z"), now)})`;
            actionButton =
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{mb: 2}}
                    onClick={() => handleSelectRoundModalOpen(roundStatus.optionalScriptSteps)}
                >
                    Select Institutions
                </Button>

        } else {

            switch (roundStatus.phase) {
                case "review": {
                    if (roundStatus.roundSubmitted) {
                        phaseTextTop = "All done!";
                        phaseTextBottom = "Thank you for submitting your reviews."

                    } else {
                        phaseTextTop = `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required}  reviews completed`;
                        phaseTextBottom = `Due:  ${format(parseISO(roundStatus.reviewEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.reviewEndDate + "Z"), now)})`;
                        showWarningIcon = isAfter(addDays(now, 7), parseISO(roundStatus.reviewEndDate + "Z"));
                    }
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={Boolean(roundStatus.roundSubmitted)}
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/video-review/${competitionMeta.competitionId}`}
                        >
                            Review
                        </Button>
                    break;
                }
                case "response": {
                    if (roundStatus.roundSubmitted) {
                        phaseTextTop = "All done!";
                        phaseTextBottom = "Thank you for submitting your responses."
                    } else {
                        phaseTextTop = `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required}  responses completed`;
                        phaseTextBottom = `Due:  ${format(parseISO(roundStatus.responseEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.responseEndDate + "Z"), now)})`;
                        showWarningIcon = isAfter(addDays(now, 7), parseISO(roundStatus.responseEndDate + "Z"));
                    }
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={Boolean(roundStatus.roundSubmitted)}
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/video-response/${competitionMeta.competitionId}`}
                        >
                            Respond
                        </Button>
                    break;
                }
                case "proxy": {
                    phaseTextTop = "The current round is in the proxy review phase.";
                    phaseTextBottom = "Please await the next round";
                    break;
                }
                case "standby": {
                    let nextPhase = "Response Phase";
                    let timeTop = format(parseISO(roundStatus.responseStartDate + "Z"), "Pp");
                    let timeBottom = formatDistance(parseISO(roundStatus.responseStartDate + "Z"), now);

                    if (roundStatus.hasProxy && isAfter(now, parseISO(roundStatus.reviewStartDate + "Z"))) {
                        nextPhase = "Proxy Phase";
                        timeTop = format(parseISO(roundStatus.proxyStartDate + "Z"), "Pp");
                        timeBottom = formatDistance(parseISO(roundStatus.proxyStartDate + "Z"), now);
                    } else if (isAfter(now, parseISO(roundStatus.responseStartDate + "Z"))) {
                        nextPhase = "Review Phase";
                        timeTop = format(parseISO(roundStatus.reviewStartDate + "Z"), "Pp");
                        timeBottom = formatDistance(parseISO(roundStatus.reviewStartDate + "Z"), now);
                    }

                    phaseTextTop = `Awaiting next phase`;
                    phaseTextBottom = `${nextPhase} starts on ${timeTop} (${timeBottom})`;
                    actionButton = null;
                    break;
                }
                case "error": {
                    phaseTextTop = `There is an error with the current event`;
                    phaseTextBottom = `Please contact the event organizer`;
                    actionButton = null;
                    break;
                }
                default: { // finished
                    phaseTextTop = `The current round is complete!`;
                    phaseTextBottom = `Results and rankings are now posted`;
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{width: "100%"}}
                            href="./results"
                        >
                            See Results
                        </Button>
                }
            }
        }
    }
    else {
        phaseTextTop = `No rounds currently available`;
        phaseTextBottom = `Please wait until the next round is scheduled`;
        actionButton = null;
    }

    // can only unenroll if no rounds finished and there is either no current round or current round response phase
    // end time is after current time.
    const canUnenroll = (!competitionMeta.roundsFinished) && (!roundStatus || isAfter(parseISO(roundStatus.responseEndDate + "Z"), now));

    // unenroll is disabled if competition has a payment and first round response phase end is within 24 hours.
    const unenrollDisabled = !!competitionMeta.paymentParticipant &&
        (roundStatus && isAfter(now, subHours(parseISO(roundStatus.responseEndDate + "Z"), 24)));

    // TODO: include cost and receipt??
    return (
        <Paper component={Grid} container size={{xs: 12}} sx={{p: 3}} elevation={0}>
            <Grid container size={{xs: 12, sm: 12, md: 8}} alignItems="center" justifyContent="center" >
                <Grid size={{xs: 12, sm: 1, md: 1}} sx={{textAlign: "center", mt: 1}}>
                    <PlaylistAddCircleIcon fontSize="large" sx={{color: "#444444"}} />
                </Grid>
                <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                    <Typography variant="h6">
                        {competitionMeta.name}
                    </Typography>
                </Grid>
                <Grid size={{xs: 1, sm: 1, md: 1}} />
                <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                    <Typography variant="subtitle2">
                        {competitionMeta.description}
                    </Typography>
                </Grid>
            </Grid>
            {participantStatus.eliminated ?
                <Grid container size={{xs: 12, sm: 12, md: 4}} sx={{mt: 3}} alignItems="center" justifyContent={{md: "end", sm: "start", xs: "center"}} offset={{xs: 0, sm: 1, md: 0}}>
                    <Grid size={{xs: 12}} sx={{textAlign: {xs: "center", sm: "left", md: "right"}}}>
                        <Typography variant="subtitle2" sx={{}}>
                            You have been disqualified from this event.
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Reason:  ${participantStatus.eliminationReason}`}
                        </Typography>
                    </Grid>
                </Grid> :
                <Grid container size={{xs: 12, sm: 12, md: 4}} sx={{mt: 3}} alignItems="center" justifyContent={{md: "end", sm: "start", xs: "center"}} offset={{xs: 0, sm: 1, md: 0}}>
                    <Grid size={{xs: 12}}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: "start", md: "end"}}}>
                            {showWarningIcon ?
                                <Tooltip title="Please Complete and Submit">
                                    <ErrorIcon color="secondary" sx={{mr: 1}} />
                                </Tooltip> :
                                null
                            }
                            <Typography variant="subtitle2">
                                {phaseTextBottom}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid sx={{mt: 2}}>
                        <Typography variant="subtitle2" textAlign={{xs: "center", sm: "left", md: "right"}}>
                            {phaseTextTop}
                        </Typography>
                        {roundStatus && (roundStatus.phase === "response" || roundStatus.phase === "review") && !roundStatus.roundSubmitted ?
                            <LinearProgress
                                aria-label={`Progress Bar ${submittedValue} of ${requiredValue} complete`}
                                color="primary"
                                variant="determinate"
                                value={100 * (submittedValue / requiredValue)}
                            /> :
                            null
                        }
                    </Grid>
                </Grid>
            }
            {competitionMeta.prizeDescription ?
                <Grid container size={{xs: 12, sm: 12, md: 8}} sx={{mt: {xs: 3}}} alignItems="center" justifyContent={{xs: "center", sm: "start"}}>
                    <Grid size={{xs: 0, sm: 1}} />
                    <Grid size={{xs: 12, sm: 11}}>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: "start"}}}>
                            <WorkspacePremiumIcon color="primary" sx={{mr: 1}} />
                            <TypographyInlineLinks variant="subtitle2" text={competitionMeta.prizeDescription} />
                        </Box>
                    </Grid>
                </Grid> :
                null
            }
            <Grid container size={{xs: 12}}>
                <Grid size={{xs: 12}}>
                    <Divider sx={{my: 3}} />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, sm: 6}} justifyContent={{xs: "center", sm: "start"}} >
                {competitionMeta.tags.map((c, i) => (
                    <Grid key={i}>
                        <Chip
                            sx={{mr: 2}}
                            variant="filled"
                            color="primary"
                            label={c.name}
                            size="small"
                        />
                    </Grid>
                    )
                )}
            </Grid>
            <Grid container size={{xs: 12, sm: 6}} spacing={2} justifyContent="end">
                {participantStatus.eliminated ?
                    null :
                    <>
                        <Grid size={{xs: 12, sm: 5}}>
                            {canUnenroll ?
                                <Button
                                    variant="outlined"
                                    color="darkPrimary"
                                    fullWidth
                                    disabled={unenrollDisabled}
                                    onClick={() => {
                                        handleUnenrollClick && handleUnenrollClick(competitionMeta.competitionId);
                                    }}
                                >
                                    Leave Event
                                </Button> :
                                null
                            }
                        </Grid>
                        <Grid size={{xs: 12, sm: 4}}>
                            {actionButton}
                        </Grid>
                    </>
                }
            </Grid>
        </Paper>
    )
}