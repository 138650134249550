import React from "react";
import {Container, Grid2 as Grid, Paper} from "@mui/material";
import {OpenCompetitionResult} from "../../data-types";
import {EnrollCard, NoDataCard} from "../../components";

interface OpenEnrollSectionProps {
    openCompetitions: OpenCompetitionResult[];
    initEnroll: (competitionId: number) => void;
}

export function OpenEnrollSection({openCompetitions, initEnroll}: OpenEnrollSectionProps) {

    return (
        <Grid container spacing={2}>
            {openCompetitions.length === 0 ?
                <Grid size={{xs: 12}}>
                    <NoDataCard message="There are no events available for enrollment." />
                </Grid> :
                null
            }
            {openCompetitions.map((c, i) =>
                <Paper component={Container} elevation={0} sx={{mb: 4}}>
                    <EnrollCard
                        key={i}
                        event={c}
                        initEnroll={initEnroll}
                    />
                </Paper>
            )}
        </Grid>
    )
}