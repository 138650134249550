import React from "react";
import {
    Avatar,
    Card,
    Button,
    CardHeader,
    CardContent,
    Typography,
    Grid2 as Grid,
    Chip, useTheme, Paper
} from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import GradingIcon from '@mui/icons-material/Grading';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import competition_placeholder from "../../img/competition_placeholder.jpg";
import {AdminCompetitionCardProps} from "../../data-types";
import {parseISO, isBefore, isAfter} from "date-fns";
import { Link } from "react-router-dom";

export function AdminCompetitionCard({competitionMeta, roundStatus, participantStatus, selectShare, canCopy}: AdminCompetitionCardProps) {
    const theme = useTheme();
    const now = new Date();
    const start = parseISO(competitionMeta.visibilityDate + "Z");
    const end = parseISO(competitionMeta.endDate + "Z");
    const hasStarted = isAfter(now, start);
    const hasEnded = isAfter(now, end);

    let statusIcon;
    if (isBefore(now, start)) {
        statusIcon = (
            <Typography variant="body2" sx={{mr: 2, mt: 2}}>
                <RadioButtonCheckedIcon sx={{verticalAlign: 'middle'}}/> Enrollment Starts Soon
            </Typography>
        )
    } else if (isBefore(now, end) && isAfter(now, start)) {
        statusIcon = (
            <Typography variant="body2" sx={{mr: 2, mt: 2}}>
                <RadioButtonCheckedIcon color="primary" sx={{verticalAlign: 'middle'}}/> Active
            </Typography>
        )
    } else {
        statusIcon = (
            <Typography variant="body2" sx={{mr: 2, mt: 2}}>
                <RadioButtonCheckedIcon color="secondary" sx={{verticalAlign: 'middle'}}/> Finished
            </Typography>
        )
    }

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar
                        src={competition_placeholder}
                        imgProps={{alt: "event-avatar"}}
                    />
                }
                action={statusIcon}
                title={competitionMeta.name}
                titleTypographyProps={{
                    variant: "h5",
                    noWrap: true
                }}
                subheader={competitionMeta.canEdit ?
                    "" :
                    "Shared with you"
                }
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, sm: 5}}>
                        <Typography variant="body2" sx={{mb: 4}}>
                            {competitionMeta.description}
                        </Typography>
                        <Typography variant="body2" sx={{mb: 2}}>
                            Created by: {competitionMeta.createdByUsername}
                        </Typography>
                        <Typography display="inline" variant="body2">
                            Tags:
                        </Typography>
                        {competitionMeta.tags.map((c, i) => (
                                <Chip
                                    sx={{ml: 2, bgcolor: theme.palette.primary.main, color: "white"}}
                                    variant="filled"
                                    label={c.name}
                                    key={i}
                                    size="small"
                                />
                            )
                        )}
                    </Grid>
                    <Grid size={{xs: 12, sm: 7}}>
                        <Paper elevation={3} sx={{p: 2}}>
                            <Typography variant="body1" sx={{mb: 1}}>
                                Current Status
                            </Typography>
                            {roundStatus ?
                                <>
                                    <Typography variant="subtitle2">
                                        {`Round ${roundStatus.roundNumber}`}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {`Phase:  ${roundStatus.phase}`}
                                    </Typography>
                                </> :
                                <Typography variant="subtitle2">
                                    This event has no round scheduled.
                                </Typography>
                            }
                            {participantStatus ?
                                <Typography variant="subtitle2">
                                    {`${participantStatus.numParticipantsRemaining} / ${participantStatus.numParticipants} participants remaining`}
                                </Typography> :
                                null
                            }
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-around" sx={{mt: 2}}>
                    <Grid size={{xs: 6, sm: 3}}>
                        <Button
                            component={Link}
                            to={`/admin/events/detail/${competitionMeta.competitionId}`}
                            variant="contained"
                            color="primary"
                            size="small"
                            fullWidth
                            startIcon={<GradingIcon />}
                        >
                            Details
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6, sm: 3}}>
                        <Button
                            variant="outlined"
                            color="darkPrimary"
                            size="small"
                            onClick={() => selectShare(competitionMeta.competitionId)}
                            startIcon={<GroupAddIcon />}
                            fullWidth
                            disabled={!competitionMeta.canEdit}
                        >
                            Share
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6, sm: 3, md: 3}}>
                        <Button
                            variant="outlined"
                            color="darkPrimary"
                            size="small"
                            startIcon={<ContentCopyIcon />}
                            component={Link}
                            disabled={!canCopy}
                            fullWidth
                            to={`/admin/events/copy/${competitionMeta.competitionId}`}
                        >
                            Copy
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6, sm: 3}}>
                        <Button
                            variant="outlined"
                            component={Link}
                            size="small"
                            to={`/admin/events/edit/${competitionMeta.competitionId}`}
                            startIcon={<EditIcon />}
                            color="warning"
                            fullWidth
                            disabled={!competitionMeta.canEdit || hasStarted || hasEnded}
                        >
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
