import React, {useState} from "react";
import {Button, Box, Grid2 as Grid, Stack, Divider, Modal, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import collegenet from "../../img/collegenet.png";

export function Copyright() {

    const [open, setOpen] = useState<boolean>(false);

    const linkStyle = {
        textDecoration: "none",
        color: "black"
    }

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box component="div">
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{p: 2, fontSize: 12}}>
                <Grid size={{xs: 12, sm: 12, md: 3}} order={{xs: 2, sm: 2, md: 1}} sx={{textAlign: "center"}}>
                    <Button onClick={() => setOpen(true)}>
                        <Box
                            component="img"
                            src={collegenet}
                            sx={{width: 200, cursor: "pointer"}}
                            alt="Powered by CollegeNET"
                        >
                        </Box>
                    </Button>
                </Grid>
                <Grid container size={{xs: 12, sm: 12, md: 6}} justifyContent="center" alignItems="center" order={{xs: 1, sm: 1, md: 2}}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Link target="_blank" style={linkStyle} to="https://collegenet.com/security">Security</Link>
                        <Link target="_blank" style={linkStyle} to="https://collegenet.com/privacy">Privacy</Link>
                        <Link target="_blank" style={linkStyle} to="https://collegenet.com/terms">Terms of Use</Link>
                        <Link target="_blank" style={linkStyle} to="https://collegenet.com/accessibility">Accessibility</Link>
                    </Stack>
                </Grid>
                <Grid size={{xs: 0, sm: 0, md: 3}} order={{xs: 3, sm: 3, md: 3}} />
            </Grid>
            <Modal
                open={open}
            >
                <Box sx={modalStyle} component="div">
                    <Typography variant="body1">
                        FocusRing® is powered by CollegeNET, the leader in higher education administrative technologies since 1979.
                    </Typography>
                    <Box sx={{mt: 2, textAlign: "right"}} component="div">
                        <Button
                            onClick={() => setOpen(false)}
                            color="primary"
                            variant="outlined"
                        >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}
