import React from "react";
import {Link, Typography, TypographyTypeMap} from "@mui/material";

interface TypographyInlineLinksProps {
    text: string;
    variant?: TypographyTypeMap["props"]["variant"];
}

export function TypographyInlineLinks({text, variant}: TypographyInlineLinksProps) {
    const result: (string | JSX.Element)[] = [];
    const urlPattern = /(\bhttps?:\/\/[^\s()<>]+(?:\([\w\d]+\)|[^[:punct]\s])*)/g;
    const urls = text.match(urlPattern);
    const temp = text.replace(urlPattern, "|||URL|||");
    const parts = temp.split("|||URL|||");

    // Combine the split parts and the matched URLs
    let i = 0;
    for (const url of urls || []) {
        if (i < parts.length) {
            result.push(parts[i++]);
            result.push(<Link key={i} href={url} target="_blank">Link</Link>);
        }
    }

    if (i < parts.length) {
        result.push(parts[i]);
    }

    return (
        <Typography variant={variant}>
            {result}
        </Typography>
    )
}