import React, {useState} from "react";
import {CompetitionMeta, UserStatistics} from "../../data-types";
import {
    Box, Grid2 as Grid, Typography, Button,
    Paper, Chip, Divider, Menu, MenuItem
} from "@mui/material";
import {Link} from "react-router-dom";
import {RankIcon} from "../../icons";
import BarChartIcon from '@mui/icons-material/BarChart';
import StarsIcon from '@mui/icons-material/Stars';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface CompletedEventCardProps {
    competitionMeta: CompetitionMeta;
    userStatistics: UserStatistics | null;
    position?: number;
}

export function CompletedEventCard({competitionMeta, userStatistics}: CompletedEventCardProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenResultsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    const handleCloseResultsMenu = () => {
        setAnchorEl(null);
        setOpen(false);
    }

    //TODO:  include cost and receipt??
    return (
        <Paper component={Grid} container size={{xs: 12}} sx={{p:3}} elevation={0} alignItems="center">
            <Grid container size={{xs: 12, sm: 8, md: 8}} alignItems="center" justifyContent="center" order={{xs: 1}}>
                <Grid size={{xs: 12, sm: 1, md: 1}} sx={{textAlign: "center", mt: 1}}>
                    <PlaylistAddCheckCircleIcon fontSize="large" sx={{color: "#444444"}} />
                </Grid>
                <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                    <Typography variant="h6">
                        {competitionMeta.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, sm: 12, md: 8}} alignItems="center" justifyContent="center" order={{xs: 2, sm: 3, md: 3}}>
                <Grid size={{xs: 0, sm: 0, md: 1}} />
                <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                    <Typography variant="subtitle2">
                        {competitionMeta.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, sm: 4, md: 4}} alignItems="center" justifyContent={{md: "end", sm: "start", xs: "center"}} order={{xs: 3, sm: 2}}>
                <Grid size={{xs: 12}}>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: "end", md: "end"}}}>
                        <CheckCircleIcon color="primary" sx={{mr: 1}} />
                        <Typography variant="subtitle2">
                            Complete
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container size={{xs: 12}} alignItems="center" spacing={2} justifyContent={{xs: "center", sm: "end", md: "end"}} order={{xs: 4}}>
                {userStatistics?.currentScore ?
                    <Grid>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <BarChartIcon sx={{mr: 1}} />
                            <Typography variant="subtitle2">
                                {userStatistics.currentScore.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid> :
                    null
                }
                {userStatistics?.percentile ?
                    <Grid>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <StarsIcon sx={{mr: 1}} />
                            <RankIcon rank={userStatistics.percentile} />
                        </Box>
                    </Grid> :
                    null
                }
            </Grid>
            <Grid container size={{xs: 12}} order={{xs: 5}}>
                <Grid size={{xs: 12}}>
                    <Divider sx={{my: 3}} />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12, sm: 6}} justifyContent={{xs: "center", sm: "start"}} sx={{mb: 3}} order={{xs: 6}}>
                {competitionMeta.tags.map((c, i) => (
                        <Grid key={i}>
                            <Chip
                                sx={{mr: 2}}
                                variant="filled"
                                color="primary"
                                label={c.name}
                                size="small"
                            />
                        </Grid>
                    )
                )}
            </Grid>
            <Grid container size={{xs: 12, sm: 6}} justifyContent="end" order={{xs: 7}}>
                <Grid size={{xs: 12, sm: 4}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenResultsMenu}
                        startIcon={<ArrowDropDownIcon />}
                        fullWidth
                    >
                        Results
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'left'}}
                        open={open}
                        onClose={handleCloseResultsMenu}
                    >
                        <MenuItem component={Link} to={`/myResults/${competitionMeta.competitionId}/reviewsOfMe`}>
                            <Typography textAlign="center" sx={{color: "black"}}>
                                Reviews of Me
                            </Typography>
                        </MenuItem>
                        <MenuItem component={Link} to={`/myResults/${competitionMeta.competitionId}/results`}>
                            <Typography textAlign="center" sx={{color: "black"}}>
                                Results
                            </Typography>
                        </MenuItem>
                        <MenuItem component={Link} to={`/myResults/${competitionMeta.competitionId}/rankings`}>
                            <Typography textAlign="center" sx={{color: "black"}}>
                                Current Rankings
                            </Typography>
                        </MenuItem>
                        <MenuItem component={Link} to={`/leaders/${competitionMeta.competitionId}`}>
                            <Typography textAlign="center" sx={{color: "black"}}>
                                Leaders
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Paper>
    )
}