import React from "react";
import {Grid2 as Grid, useMediaQuery} from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid} from 'recharts';
import {UserScoreData} from "../../data-types";

interface PromptAggregateBarChartProps {
    userScoreData: UserScoreData | undefined;
}

interface ChartData {
    name: string;
    "Your Average": number;
    "Field Average": number;
}

export function PromptAggregateBarChart({userScoreData}: PromptAggregateBarChartProps) {

    // targeting samsung galaxy s20 in chrome dev tools as max phone width
    const isPhone = useMediaQuery("(max-width:413px)");
    const isTablet = useMediaQuery("(max-width:1000px)");
    let width: number;
    if (isPhone) {
        width = 300;
    } else if (isTablet) {
        width = 450;
    } else { // is desktop
        width = 720;
    }

    // marshall data to work with bar chart
    // iterate through review statistics, keep track of running sum and count
    const aggData: Map<number, {count: number, sum: number, average: number}> = new Map();
    userScoreData?.reviewStatistics.forEach(c => {
        const responseId = c.responseId;
        const _agg = aggData.get(responseId);
        if (_agg) {
            aggData.set(responseId, {..._agg, count: _agg.count + 1, sum: _agg.sum + c.raw});
        } else {
            aggData.set(responseId, {count: 1, sum: c.raw, average: 0});
        }
    })

    // iterate through responseInfo and add median and average
    userScoreData?.responseInfo.forEach(c => {
        const responseId = c.responseId;
        const _agg = aggData.get(responseId);
        if (_agg) {
            aggData.set(responseId, {..._agg, average: c.averageScore || 0});
        } else {
            aggData.set(responseId, {count: 0, sum: 0, average: c.averageScore || 0});
        }
    })

    const data: ChartData[] = Array.from(aggData.values()).map((c, i) => {
        return {
            name: `Response ${i + 1}`,
            "Your Average": parseFloat((c.sum / (c.count || 1)).toFixed(2)),
            "Field Average": parseFloat(c.average.toFixed(2))
        }
    })

    return (
        <Grid container>
            <Grid size={{xs: 12}} sx={{my: 3}}>
                <BarChart
                    width={width}
                    height={300}
                    data={data}
                    barSize={20}
                >
                    <CartesianGrid strokeDasharray="3 7"  vertical={false} />
                    <Tooltip
                        contentStyle={{backgroundColor: "#F7F7F7"}}
                        cursor={false}
                    />
                    <YAxis domain={[0, 10]} />
                    <XAxis dataKey="name" />
                    <Legend />
                    <Bar dataKey="Your Average" fill="#28871d" />
                    <Bar dataKey="Field Average" fill="#EB0052" />
                </BarChart>
            </Grid>
        </Grid>
    )
}