import React from "react";
import {Grid2 as Grid, Typography, Divider, useMediaQuery} from "@mui/material";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid} from 'recharts';
import {UserScoreData} from "../../data-types";

interface AggregateRoundChartProps {
    userScoreData: UserScoreData[];
}

export function AggregateRoundChart({userScoreData}: AggregateRoundChartProps) {

    const isPhone = useMediaQuery("(max-width:500px)");
    const isTablet = useMediaQuery("(max-width:1025px)");
    let width: number;
    if (isPhone) {
        width = 300;
    } else if (isTablet) {
        width = 500;
    } else { // is desktop
        width = 820;
    }

    const colors = ["#28871d", "#519b41", "#72ae62", "#92c283", "#b2d6a4", "#d1eac7" ];
    const possibleScoresKeys = [
        "1.0", "1.5", "2.0", "2.5", "3.0", "3.5",
        "4.0", "4.5", "5.0", "5.5", "6.0", "6.5",
        "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0"
    ];
    const numRounds = userScoreData.length;

    const roundKeys = new Array(numRounds).fill(0).map((c, i) => `Round ${i + 1}`);
    const map: Map<string, number[]> = new Map(possibleScoresKeys.map(c => [c, new Array(numRounds).fill(0)]));

    userScoreData.forEach((c, i) => {
        c.reviewStatistics.forEach(cc => {
            const scoreKey = Math.floor((Math.max(cc.raw, 1) * 2) / 2).toFixed(1);
            const counts = map.get(scoreKey);
            if (counts) {
                counts[i] += 1;
            }
        })
    })

    // [{name: "1.0", "Round 1": number, "Round 2": number}, {name: "1.5", "Round 1": number, etc}]
    const data = Array.from(map).map(([scoreKey, scoresPerRound]) => {
        const rounds = scoresPerRound.reduce((p, c, ci) => {
            return {
                ...p,
                [`Round ${ci + 1}`]: c
            }
        }, {})
        return {
            name: scoreKey,
            ...rounds
        }
    })

    return (
        <Grid container>
            <Grid size={{xs: 12}}>
                <Typography variant="h6">
                    Round Aggregate
                </Typography>
                <Divider sx={{my: 3}} />
            </Grid>
            <Grid size={{xs: 12}}>
                <BarChart
                    width={width}
                    height={250}
                    data={data}
                    margin={{top: 0, bottom: 0, right: 0, left: 0}}
                >
                    <CartesianGrid strokeDasharray="3 7"  vertical={false} />
                    <Tooltip
                        contentStyle={{backgroundColor: "#F7F7F7"}}
                        cursor={false}
                    />
                    <YAxis domain={[0, 10]} />
                    <XAxis dataKey="name" />
                    <Legend />
                    {roundKeys.map((c, i) => (
                        <Bar key={i} dataKey={c} stackId="a" fill={colors[i % 6]} />
                    ))}
                </BarChart>
            </Grid>
        </Grid>
    )
}