import React, {useState} from "react";
import {Container, Grid2 as Grid, Typography, Chip, ClickAwayListener, Tooltip, IconButton} from "@mui/material";
import {LoadingSpinner, ErrorComponent, RankingsLeaders} from "../../components";
import {useParams} from "react-router-dom";
import {useRankings} from "./useRankings";
import {RoundRankLists} from "./RoundRankLists";
import {RankIcon} from "../../icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export function Rankings() {

    const {competitionId} = useParams();
    const {
        roundRanks,
        requestStatus,
        selectedRoundIndex,
        handleRoundChange
    } = useRankings(competitionId);

    const [openTooltip, setOpenTooltip] = useState<boolean>(false);

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        const ranks = isNaN(selectedRoundIndex) ? undefined : roundRanks[selectedRoundIndex];

        return (
            <Container sx={{mb: 10}}>
                <Grid container sx={{}} alignItems="center" justifyContent="end">
                    <Grid>
                        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                            <Tooltip
                                PopperProps={{disablePortal: true}}
                                onClose={() => setOpenTooltip(false)}
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                placement="bottom-end"
                                title={<>
                                    <Typography variant="subtitle2" sx={{mb: 2}}>
                                        At the end of each round, your scores are compiled into a Composite Score and you are assigned a ranking.  Your ranking corresponds to your Score Percentile.
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.981} />
                                            <Typography variant="subtitle2">
                                                98th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.961} />
                                            <Typography variant="subtitle2">
                                                96th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.941} />
                                            <Typography variant="subtitle2">
                                                94th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.921} />
                                            <Typography variant="subtitle2">
                                                92th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.901} />
                                            <Typography variant="subtitle2">
                                                90th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.801} />
                                            <Typography variant="subtitle2">
                                                80th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.701} />
                                            <Typography variant="subtitle2">
                                                70th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.601} />
                                            <Typography variant="subtitle2">
                                                60th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.501} />
                                            <Typography variant="subtitle2">
                                                50th percentile
                                            </Typography>
                                        </Grid>
                                        <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                                            <RankIcon rank={0.1} />
                                            <Typography variant="subtitle2">
                                                &lt; 50th percentile
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>}
                            >
                                <IconButton onClick={() => setOpenTooltip(true)} size="small">
                                    <HelpOutlineIcon color="disabled" />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </Grid>
                </Grid>
                <Grid container>
                    {/* Leaders */}
                    <Grid size={{xs: 12}} sx={{mb: 5}}>
                        <RankingsLeaders roundRanks={roundRanks} competitionId={competitionId} />
                    </Grid>
                    {/* Round Select */}
                    {/* only shown if more than one round */}
                    <Grid size={{xs: 12}} sx={{mb: 5}}>
                        {roundRanks.length > 1 ?
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Typography variant="subtitle2">
                                        Round Select
                                    </Typography>
                                </Grid>
                                {roundRanks.map((c, i) =>
                                    <Grid key={i}>
                                        <Chip
                                            label={c.roundNumber}
                                            variant={i === selectedRoundIndex ? "filled" : "outlined"}
                                            color="secondary"
                                            onClick={() => handleRoundChange(i)}
                                        />
                                    </Grid>
                                )}
                            </Grid> :
                            null
                        }
                    </Grid>
                    {/* Gold, Silver, Bronze */}
                    <Grid size={{xs: 12}}>
                        <RoundRankLists ranks={ranks} />
                    </Grid>
                </Grid>
            </Container>
        )
    }
}