import React from "react";
import {Modal, Grid2 as Grid, Typography, Divider, TextField, MenuItem, Button} from "@mui/material";
import {ModalBox} from "../../components/modal-box";
import {useProfileEdit} from "../../hooks";
import {UserProfileState} from "../../data-types";
import {AgeOptions, CountryOptions, LanguageOptions, DataValidation} from "../../util";
import {toast} from "react-toastify";

interface ProfileEditModalProps {
    open: boolean;
    onClose: () => void;
    user: UserProfileState;
    handleCancel: () => void;
    handleSave: (u: UserProfileState) => void;
    residentOnly: boolean;
}

export function ProfileEditModal({open, onClose, user, handleCancel, handleSave, residentOnly}: ProfileEditModalProps) {

    const {
        handleChange,
        editedProfileState,
        submitProfileEdits
    } = useProfileEdit(user);

    const submit = () => {
        if (!residentOnly && DataValidation.isUserProfileIncomplete(editedProfileState)) {
            toast.error("Please complete all fields.");
            return;
        }
        if (residentOnly && editedProfileState.usResident === null) {
            toast.error("Please complete all fields.");
            return;
        }
        submitProfileEdits()
            .then(_ => {
                handleSave(editedProfileState);
                toast.success("Profile saved");
            })
            .catch(e => {
                toast.error(e);
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalBox>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6" sx={{mb: 1}}>
                            Complete Your Profile
                        </Typography>
                        <Typography variant="subtitle2">
                            In order to enroll in this event, you must complete and save the following information:
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    {user.ageBracket === null && !residentOnly ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                select
                                required
                                label="Age Bracket"
                                name="ageBracket"
                                id="ageBracket"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.ageBracket ?? -1}
                            >
                                {AgeOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === -1}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> :
                        null
                    }
                    {user.regionOrCountry === null && !residentOnly ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                select
                                required
                                label="Country"
                                id="regionOrCountry"
                                name="regionOrCountry"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.regionOrCountry ?? "Not Specified"}
                            >
                                {CountryOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === "Not Specified"}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> :
                        null
                    }
                    {user.primaryLanguage === null && !residentOnly ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                select
                                required
                                id="primaryLanguage"
                                label="Primary Language"
                                name="primaryLanguage"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.primaryLanguage ?? "Not Specified"}
                            >
                                {LanguageOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === "Not Specified"}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> :
                        null
                    }
                    {user.mailingZip === null && !residentOnly ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                id="mailingZip"
                                required
                                disabled={!(editedProfileState.regionOrCountry)}
                                label="Home Postal Code"
                                name="mailingZip"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.mailingZip ?? ""}
                                helperText={editedProfileState.regionOrCountry === "US" ?
                                    "Format:  12345 or 12345-6789" :
                                    ""
                                }
                                slotProps={{
                                    htmlInput: {maxLength: 10}
                                }}
                            />
                        </Grid> :
                        null
                    }
                    {user.highSchoolZip === null && !residentOnly ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                required
                                id="highSchoolZip"
                                disabled={!(editedProfileState.regionOrCountry)}
                                label="High School Postal Code"
                                name="highSchoolZip"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.highSchoolZip ?? ""}
                                helperText={editedProfileState.regionOrCountry === "US" ?
                                    "Format:  12345 or 12345-6789" :
                                    ""
                                }
                                slotProps={{
                                    htmlInput: {maxLength: 10}
                                }}
                            />
                        </Grid> :
                        null
                    }
                    {user.usResident === null ?
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                select
                                required
                                id="usResident"
                                label="US Resident"
                                name="usResident"
                                fullWidth
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={
                                    (editedProfileState.usResident === undefined ||
                                        editedProfileState.usResident === null) ?
                                        "Not Specified" :
                                        (editedProfileState.usResident) ?
                                            1 :
                                            0
                                }
                            >
                                <MenuItem
                                    value={"Not Specified"}
                                    disabled
                                >
                                    Not Specified
                                </MenuItem>
                                <MenuItem
                                    value={1}
                                >
                                    Yes
                                </MenuItem>
                                <MenuItem
                                    value={0}
                                >
                                    No
                                </MenuItem>
                            </TextField>
                        </Grid> :
                        null
                    }
                </Grid>
                <Grid container spacing={2} sx={{mt: 3}}>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Button
                            fullWidth
                            color="secondary"
                            variant="outlined"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={submit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </ModalBox>
        </Modal>
    )
}