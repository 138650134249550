import React, {useEffect, useState, useContext} from "react";
import {
    Box, AppBar, Toolbar, IconButton,
    Badge, Divider, Menu,
    Typography, Button, MenuItem, Avatar,
    Link
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';
import FocusRingLogo from "../../img/focus_ring_logo.png";
import {DataAccess} from "../../util";
import {Notification} from "../../data-types";
import { MessageDrawer } from "../message-drawer";
import {UserContext, BrandingContext} from "../../context";
import {useTokenizedUrl} from "../../hooks";
import {RedirectModal} from "../redirect-modal";
import {toast} from "react-toastify";
import {Promotions} from "./Promotions";

type LinkData = {
    name: string;
    url: string;
}

// adapted from the "App bar with responsive menu" at:  https://mui.com/material-ui/react-app-bar
export function NavBar() {

    const {user, setUser} = useContext(UserContext);
    const {logoUrl} = useContext(BrandingContext);
    const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
    const {getTokenizedUrl} = useTokenizedUrl();

    const pageURLs:LinkData[]  = [
        {name: "My Events", url: "/myEvents"},
        {name: "My Results", url: "/myResults"}
    ]

    const avatarURLs: LinkData[] = [
        {name: "Profile", url: "/user/profile"},
        {name: "Enroll", url: "/enroll"},
        // {name: "Worklist", url: "/worklist"}
    ]

    const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null);
    const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
    const [profileOpen, setProfileOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    useEffect(() => {
        const getData = async () => {
            const response = await DataAccess.get("/api/notification/get.json");
            setNotifications(response.notifications);
        }

        getData()
            .catch(e => {
                console.log(e);
            })
    }, [])

    const logout = () => {
        DataAccess.postFormString("/logout", "")
            .then(_ => {
                setUser(null);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
        setHamburgerOpen(true);
    }

    const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
        setProfileOpen(true);
    }

    const handleCloseMenu = () => {
        setAnchorElNav(null);
        setHamburgerOpen(false);
        setProfileOpen(false);
    }

    const handleKnowledgeBaseClick = () => {
        // open the modal, then call token service.
        // when token service resolves, window.open to navigate to url returned from token hook
        setRedirectModalOpen(true);
        getTokenizedUrl()
            .then(tokenizedUrl => {
                window.open(tokenizedUrl);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was an error in opening the external link.");
            })
            .finally(() => setRedirectModalOpen(false));
    }

    const handleRead = (id: number) => {
        // find the notification
        let n = notifications.find(e => e.notificationId === id)!;
        // create payload, flipping the read bit
        let p = {notifications: [n.notificationId], isRead: !n.isRead};
        DataAccess.put(`/api/notification/putRead.json`, {data: p})
            .then(_ => {
                // update the state client-side
                setNotifications(prev => {
                    return prev.map((c) => {
                       return (c.notificationId === id) ? {...c, isRead: !c.isRead} : c;
                    })
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    const handleReadAll = () => {
        const data = {notifications: notifications.map(n => n.notificationId), isRead: true};
        DataAccess.put(`/api/notification/putRead.json`, {data: data})
            .then(_ => {
                setNotifications(prev => prev.map(c => {
                    return {...c, isRead: true}
                }))
            })
            .catch(e => {
                console.log(e);
            })
    }

    const handleDelete = (id: number) => {
        let n = notifications.find(e => e.notificationId === id)!;
        let p = {notifications: [n.notificationId], isTrash: true};
        DataAccess.put(`/api/notification/putTrash.json`, {data: p})
            .then(_ => {
                setNotifications(prev => {
                    return prev.filter((c) => c.notificationId !== id)
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    const handleDeleteAll = () => {
        const data = {notifications: notifications.map(n => n.notificationId), isTrash: true};
        DataAccess.put("/api/notification/putTrash.json", {data: data})
            .then(_ => {
                setNotifications([]);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                (
                    (event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift'
                )
            ) {
                return;
            }
            setDrawerOpen(open);
        }

    // are all notifications read?
    const allNotificationsRead = notifications.every(c => c.isRead);

    // can user see admin tab?
    const isAdminVisible = user && (
        user.role === "admin" || user.role === "view" || user.role === "p_admin" || user.role === "ps_admin" || user.role === "event_admin"
    )

    return (
        <Box sx={{pt: 0}} component="div">
            <Box sx={{textAlign: "center"}} component="div">
                {/* Skip link to main content (top-level Container element of each page */}
                <Link
                    href={"#mainContent"}
                    sx={{
                        fontSize: 0,
                        textAlign: "center",
                        "&:focus": {
                            pt: 2,
                            color: "black",
                            fontSize: 16
                        }
                    }}
                >
                    Skip to Content
                </Link>
            </Box>
            <AppBar
                position="static"
                sx={{
                    bgcolor: "transparent",
                    boxShadow: "none",
                    backgroundImage: "none",
                    mb: 2,
                }}
            >
                <Toolbar sx={{flexWrap: 'nowrap'}}>
                    <RouterLink to="/">
                        <Box
                            component="img"
                            sx={{height: {xs: 45, sm: 70}, maxHeight: 70}}
                            alt={logoUrl ? "Institution" : "FocusRing"}
                            src={logoUrl ?? FocusRingLogo}
                        />
                    </RouterLink>
                    <Box sx={{display: {xs: 'flex', md: 'none'}, ml: {xs: 'auto'}}} component="div">
                        <IconButton size="large" onClick={handleOpenNavMenu}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                            keepMounted
                            transformOrigin={{vertical: 'top', horizontal: 'left'}}
                            open={hamburgerOpen}
                            onClose={handleCloseMenu}
                            sx={{display: {xs: 'block', md: 'none'}}}
                        >
                            {pageURLs.map((cur, i) => (
                                <MenuItem key={i} onClick={handleCloseMenu} component={RouterLink} to={cur.url}>
                                    <Typography textAlign="center" sx={{color: "black"}}>{cur.name}</Typography>
                                </MenuItem>
                            ))}
                            {user?.role === "admin" ?
                                <MenuItem onClick={handleCloseMenu} component={RouterLink} to={"/analytics"}>
                                    <Typography textAlign="center" sx={{color: "black"}}>Analytics</Typography>
                                </MenuItem> :
                                null
                            }
                            {user?.role === "admin" ?
                                <MenuItem onClick={handleCloseMenu} component={RouterLink} to={"/admin"}>
                                    <Typography textAlign="center" sx={{color: "black"}}>Admin</Typography>
                                </MenuItem> :
                                null
                            }
                            {user?.isReviewer ?
                                <MenuItem onClick={handleCloseMenu} component={RouterLink} to={"/reviewer"}>
                                    <Typography textAlign="center" sx={{color: "black"}}>Reviewer</Typography>
                                </MenuItem> :
                                null
                            }
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, ml: 2}} component="div">
                        {pageURLs.map((cur, i) => (
                            <Button key={i} component={RouterLink} to={cur.url} style={{textDecoration: "none", color: "black"}}>
                                {cur.name}
                            </Button>
                        ))}
                        {isAdminVisible ?
                            <>
                                <Button
                                    component={RouterLink}
                                    to={"/analytics"}
                                    onClick={handleCloseMenu}
                                    sx={{textDecoration: "none", color: "black"}}
                                >
                                    Analytics
                                </Button>
                                <Button
                                    component={RouterLink}
                                    to={"/admin"}
                                    onClick={handleCloseMenu}
                                    sx={{textDecoration: "none", color: "black"}}
                                >
                                    Admin
                                </Button>
                            </> :
                            null
                        }
                        {user?.isReviewer ?
                            <Button
                                component={RouterLink}
                                to={"/reviewer"}
                                onClick={handleCloseMenu}
                                sx={{textDecoration: "none", color: "black"}}
                            >
                                Reviewer
                            </Button> :
                            null
                        }
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end"}} component="div">
                        <IconButton onClick={toggleDrawer(true)} size="large" color="inherit" sx={{height: "100%"}} aria-label={allNotificationsRead ? "Notifications" : "Unread Notifications"}>
                            <Badge variant="dot" invisible={allNotificationsRead} color="secondary">
                                <MailIcon sx={{color: "#444444"}} titleAccess={allNotificationsRead ? "Notifications" : "Unread Notifications"} />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" color="inherit" sx={{height: "100%"}} onClick={handleKnowledgeBaseClick}>
                            <HelpOutlineIcon sx={{color: "#444444"}} titleAccess="Help"/>
                        </IconButton>
                        <IconButton sx={{}} onClick={handleOpenProfileMenu} aria-label="Profile">
                            <Avatar
                                src={user ? `data:image/jpeg;base64,${user.avatar}` : ""}
                                alt="User avatar"
                                sx={{bgcolor: "#444444"}}
                            >
                                <PersonIcon titleAccess="User avatar"/>
                            </Avatar>
                        </IconButton>
                        <Menu
                            id="menu-profile"
                            anchorEl={anchorElNav}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            keepMounted
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            open={profileOpen}
                            onClose={handleCloseMenu}
                            sx={{display: 'block'}}
                        >
                            {avatarURLs.map((cur, i) => (
                                <MenuItem key={i} onClick={handleCloseMenu} component={RouterLink} to={cur.url} >
                                    <Typography textAlign="center" sx={{color: "black"}}>{cur.name}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={() => logout()}>
                                <Typography textAlign="center" sx={{color: "black"}}>Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Promotions />
            <Divider variant="middle" sx={{mb: 8}}/>
            <MessageDrawer
                notifications={notifications}
                open={drawerOpen}
                setClose={toggleDrawer(false)}
                handleRead={handleRead}
                handleDelete={handleDelete}
                handleReadAll={handleReadAll}
                handleDeleteAll={handleDeleteAll}
            />
            <RedirectModal
                open={redirectModalOpen}
                setOpen={setRedirectModalOpen}
                action={() => {}}
            />
        </Box>
    )
}