import React, {useState, ChangeEvent} from "react";
import {
    Paper,
    Grid2 as Grid,
    Typography,
    Container,
    Button,
    LinearProgress,
    Pagination,
    Box,
    Stack
} from "@mui/material";
import {Link} from "react-router-dom";
import {addSeconds, addDays, format, isAfter, formatDistanceToNow} from "date-fns";
import {Competition, RequestStatus} from "../../data-types";
import {LoadingSpinner, ErrorComponent} from "../../components";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface ActiveEventsProps {
    activeEvents: Competition[];
    requestStatus: RequestStatus;
}

export function ActiveEvents({activeEvents, requestStatus}: ActiveEventsProps) {

    const [page, setPage] = useState<number>(1);

    const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const renderContent = () => {
        if (requestStatus === "loading") {
            return <LoadingSpinner />;
        } else if (requestStatus === "error") {
            return <ErrorComponent />;
        } else {
            // no active events with todos
            if (activeEvents.length === 0) {
                return (
                    <Grid container sx={{p: 2}}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="subtitle2">
                                You currently have nothing to do for active events.
                            </Typography>
                        </Grid>
                    </Grid>
                )
            } else {
                // compute content
                const current = activeEvents[page - 1];
                const required = current.roundStatus?.todo?.required || 0;
                const submitted = current.roundStatus?.todo?.submitted || 0;
                const phase = current.roundStatus?.phase || "";
                const competitionId = current.competitionMeta!.competitionId;
                const activeTime = current.competitionMeta!.activeTime!;
                const competitionName = current.competitionMeta?.name;
                const competitionDescription = current.competitionMeta?.description;
                const optionalScriptSteps = current.roundStatus?.optionalScriptSteps;
                const now = new Date();
                const end = addSeconds(now, -1 * activeTime);

                return (
                    <Grid container size={{xs: 12}} sx={{p: 2}} alignItems="start">
                        <Grid container size={{xs: 12, sm: 12, md: 8}} alignItems="center" justifyContent="center" spacing={1}>
                            <Grid size={{xs: 12, sm: 1, md: 1}} sx={{mt: 1, textAlign: "center"}}>
                                <PlaylistAddCircleIcon fontSize="large" sx={{color: "#444444"}} />
                            </Grid>
                            <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                                <Typography variant="h5">
                                    {competitionName}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 0, sm: 1}} />
                            <Grid size={{xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: "left"}}}>
                                <Typography variant="subtitle2">
                                    {competitionDescription}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container size={{xs: 12, sm: 12, md: 4}} sx={{mt: 3}} alignItems="center" justifyContent={{md: "end", sm: "start", xs: "center"}} offset={{xs: 0, sm: 1, md: 0}}>
                            <Grid size={{xs: 12}}>
                                <Box sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: "start", md: "end"}}}>
                                    {isAfter(addDays(now, 7), end) ?
                                        <Grid>
                                            <ErrorIcon color="secondary" sx={{mr: 1}} />
                                        </Grid> :
                                        null
                                    }
                                    <Typography variant="subtitle2">
                                        {`Due:  ${format(end, "Pp")} (${formatDistanceToNow(end)})`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid size={{}} sx={{mt: 2}}>
                                <Typography variant="subtitle2" textAlign={{xs: "center", sm: "left", md: "right"}}>
                                    {`${submitted} / ${required} ${phase === "response" ? "responses" : "reviews"} complete`}
                                </Typography>
                                <LinearProgress
                                    aria-label={`Progress Bar ${submitted} of ${required} complete`}
                                    variant="determinate"
                                    value={100 * (submitted / required)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container size={{xs: 12}} justifyContent="end">
                            <Grid size={{xs: 12, sm: 4}}>
                                <Button
                                    component={Link}
                                    to={(phase === "response") ? `/video-response/${competitionId}` : `video-review/${competitionId}`}
                                    variant="contained"
                                    color="primary"
                                    sx={{my: 2, textAlign: "right"}}
                                    fullWidth
                                >
                                    {`${required - submitted} ${(phase === "response" ? "Responses" : "Reviews")} Due`}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        }
    }

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230}}>
            <Grid container spacing={2} alignItems="center" sx={{px: 2, pt: 2}}>
                <Grid size={{xs: 6}}>
                    <Typography variant="h4" color="primary">
                        To Do
                    </Typography>
                </Grid>
                <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                    <Button
                        color="darkPrimary"
                        component={Link}
                        to="/myEvents"
                    >
                        View All Events
                    </Button>
                </Grid>
            </Grid>
            {renderContent()}
            <Grid container justifyContent="center">
                {activeEvents.length > 1 ?
                    <Stack alignItems="center">
                        <Pagination page={page} count={activeEvents.length} onChange={handleChange} />
                        <Typography variant="subtitle2" sx={{mt: 2}}>
                            {`Event:  ${page} of ${activeEvents.length}`}
                        </Typography>
                    </Stack> :
                    null
                }
            </Grid>
        </Paper>
    )
}