import React, {useState} from "react";
import {
    Container,
    Paper,
    Grid2 as Grid,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    Box,
    Divider, TextField
} from "@mui/material";
import {useModerationDetail} from "../../hooks";
import {useParams, useNavigate} from "react-router-dom";
import {LoadingSpinner, ErrorComponent, VideoPlayer} from "../../components";
import {EliminationModal} from "../admin-report-detail/EliminationModal";
import {VideoJsPlayerOptions} from "video.js";
import ErrorIcon from '@mui/icons-material/Error';
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CancelIcon from "@mui/icons-material/Cancel";
import {toast} from "react-toastify";

export function AdminModerationDetail() {

    const {responseId} = useParams();
    const navigate = useNavigate();

    const {
        requestStatus,
        moderationDetail,
        handleModeration,
        editedDockScore,
        handleDockScoreChange
    } = useModerationDetail(responseId);
    const [eliminationModalOpen, setEliminationModalOpen] = useState<boolean>(false);

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: moderationDetail.sources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    const _moderationResults = moderationDetail.moderationResults.results;
    const flaggedTypes: string[] = (_moderationResults.length > 0) ?
        Object.entries(_moderationResults[0].categories).filter(([,v]) => v).map(([k]) => k) :
        [];

    const submit = (action: number) => {
        if (responseId) {
            handleModeration(parseInt(responseId), action, editedDockScore)
                .then(_ => {
                    toast.success("Moderation adjustment successful.");
                    navigate(`/admin/round/detail/${moderationDetail.roundId}`);
                })
                .catch(e => {
                    console.log(e);
                    toast.error(e);
                })
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} sx={{mb: 5}} alignItems="start">
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4" sx={{mb: 6}}>
                            Content Moderation
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 6}}>
                        <VideoPlayer
                            options={videoOptions}
                        />
                    </Grid>
                    <Grid container spacing={2} size={{xs: 12, sm: 12, md: 6}}>
                        <Grid size={{xs: 12}} sx={{mb: 5}}>
                            <Typography variant="subtitle2">
                                {`User:  ${moderationDetail.username}`}
                            </Typography>
                            <Typography variant="subtitle2">
                                {`Name:  ${moderationDetail.firstName} ${moderationDetail.lastName}`}
                            </Typography>
                            <Typography variant="subtitle2">
                                {`Email:  ${moderationDetail.email}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Box component="div" sx={{display: "flex", alignItems: "center", mb: 3}}>
                                <ErrorIcon color="secondary" sx={{mr: 1}} />
                                <Typography variant="h6">
                                    This video was flagged for the following categories:
                                </Typography>
                            </Box>
                            <List dense>
                                {flaggedTypes.map(c =>
                                    <ListItem component={Paper} elevation={2} key={c} sx={{mb: 1}}>
                                        <ListItemText
                                            primary={`- ${c}`}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mb: 5}}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5">
                            Actions
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Typography variant="subtitle2" sx={{p: 3, textAlign: "center"}}>
                                Select Ignore to disregard this content moderation.  Select Dock Score and a penalty between 0 - 9 to penalize this user that many points at the conclusion of the Review and/or Proxy Review pages.  Select Eliminate to remove them from the event entirely.  Elimination is permanent and cannot be undone.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DoNotDisturbOnIcon />}
                            fullWidth
                            onClick={() => submit(1)}
                        >
                            Ignore
                        </Button>
                    </Grid>
                    <Grid container size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                        <Grid size={{xs: 12, sm: 12, md: 4}}>
                            <TextField
                                label="Penalty"
                                id="reportedDockScore"
                                size="small"
                                type="number"
                                color="warning"
                                focused
                                error={editedDockScore < 0.5 || editedDockScore > 9}
                                fullWidth
                                onChange={handleDockScoreChange}
                                value={editedDockScore}
                                slotProps={{
                                    htmlInput: {step: 0.5}
                                }}
                            />
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 8}}>
                            <Button
                                variant="contained"
                                color="warning"
                                startIcon={<ArrowCircleDownIcon />}
                                fullWidth
                                onClick={() => submit(2)}
                            >
                                Dock Score
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            startIcon={<CancelIcon />}
                            onClick={() => setEliminationModalOpen(true)}
                        >
                            Eliminate
                        </Button>
                    </Grid>
                </Grid>
                <EliminationModal
                    open={eliminationModalOpen}
                    setOpen={setEliminationModalOpen}
                    action={() => submit(3)}
                />
            </Container>
        )
    }
}