import React, {useState} from "react";
import {Competition}  from "../../data-types";
import {Menu, MenuItem, Button, ListItemText, ListItemIcon} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ResultsDrawerContentProps {
    completedCompetitions: Competition[];
    competitionId?: string;
    handleCompetitionChange: (id: number) => void;
}

export function CompetitionSelectList({completedCompetitions, competitionId, handleCompetitionChange}: ResultsDrawerContentProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    }

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleOpen}
                startIcon={<ArrowDropDownIcon />}
            >
                Events
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                keepMounted
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={open}
                onClose={handleClose}
            >
                {completedCompetitions.map((c, i) => c.competitionMeta ?
                    <MenuItem
                        key={i}
                        onClick={() => {
                            handleCompetitionChange(c.competitionMeta!.competitionId);
                            handleClose();
                        }}
                    >
                        {parseInt(competitionId || "") === c.competitionMeta.competitionId ?
                            <ListItemIcon>
                                <CheckCircleIcon color="primary" />
                            </ListItemIcon> :
                            null
                        }
                        <ListItemText>{c.competitionMeta!.name}</ListItemText>
                        {/*<Typography textAlign="center" sx={{color: "black"}}>*/}

                        {/*</Typography>*/}
                    </MenuItem> :
                    null
                )}
            </Menu>
        </>
    )
}