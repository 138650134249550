import {useState, useEffect, useCallback, ChangeEvent} from "react";
import {RequestStatus, VideoSource, IModerationResults} from "../../data-types";
import {DataAccess} from "../../util";

interface ModerationDetail {
    userId: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    roundId: number;
    responseId: number;
    moderationResults: IModerationResults;
    videoId: number;
    responseVideoUuid: string;
    sources: VideoSource[];
}

export const useModerationDetail = (responseId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [moderationDetail, setModerationDetail] = useState<ModerationDetail>({
        userId: NaN,
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        roundId: NaN,
        responseId: NaN,
        moderationResults: {results: []},
        videoId: NaN,
        responseVideoUuid: "",
        sources: []
    });

    const [editedDockScore, setEditedDockScore] = useState<number>(0.5);

    useEffect(() => {
        const controller = new AbortController();
        const getModerationDetail = async () => {
            if (responseId) {
                const r = await DataAccess.get(`/api/moderation/${responseId}/getDetail.json`, {signal: controller.signal});
                // parse moderationResults, sort on true categories
                const parsed: IModerationResults = JSON.parse(r.moderationDetail.moderationResults);
                setModerationDetail({...r.moderationDetail, moderationResults: parsed});
            }
        }

        getModerationDetail()
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.error(e);
                setRequestStatus("error");
            })

        return () => {
            controller.abort();
        }

    }, [responseId]);

    const handleDockScoreChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {valueAsNumber} = e.target;
        if (valueAsNumber >= 0.5 && valueAsNumber <= 9) {
            setEditedDockScore(valueAsNumber);
        }
    }, []);

    const handleModeration = useCallback(async (responseId: number, action: number, editedDockScore: number) => {
        const data = {
            action: action,
            penalty: (action === 2) ? editedDockScore : null
        }

        await DataAccess.post(`/api/moderation/${responseId}/handle.json`, {data: data});
    }, []);

    return {
        requestStatus: requestStatus,
        moderationDetail: moderationDetail,
        handleModeration: handleModeration,
        editedDockScore: editedDockScore,
        handleDockScoreChange: handleDockScoreChange
    }
}