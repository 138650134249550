import React, {useState} from "react";
import {Box, Container, Typography, Grid2 as Grid, List, Paper, Divider, Button, TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import {useReportDetail} from "../../hooks";
import {VideoPlayer, LoadingSpinner, ErrorComponent} from "../../components";
import {VideoJsPlayerOptions} from "video.js";
import {ReportCommentListItem} from "./ReportCommentListItem";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {EliminationModal} from './EliminationModal';

export function AdminReportDetail() {

    const {responseId} = useParams();
    const {
        requestStatus,
        reportDetail,
        handleReport,
        handleDockScoreChange,
        editedDockScore
    } = useReportDetail(responseId);
    const [eliminationModalOpen, setEliminationModalOpen] = useState<boolean>(false);

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: reportDetail.responseSources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    let previousActionText: string;
    let reportText: string;

    switch (reportDetail.reportedAction) {
        case undefined:
        case 0: {
            previousActionText = "None";
            reportText = "You have not previously taken action on this response.  Select Ignore to disregard currently reported reviews.  Select Dock Score and a penalty between 0 - 9 to penalize this user that many points at the conclusion of the Review and/or Proxy Review pages.  Select Eliminate to remove them from the event entirely.  Elimination is permanent and cannot be undone.";
            break;
        }
        case 1: {
            previousActionText = "Ignore";
            reportText = "You have chosen to ignore reports for this response.  You can click Confirm or Ignore again to maintain your decision, or you can dock score or eliminate with the buttons below."
            break;
        }
        case 2: {
            previousActionText = `Dock Score ${reportDetail.reportedDockScore} points`;
            reportText = "You have chosen to dock this user's score.  You can confirm this deduction, remove this deduction by selecting Ignore, change the deduction and click Dock Score, or eliminate the user.";
            break;
        }
        default: {
            previousActionText = "Elimination";
            reportText = "You have eliminated the user that submitted this response, no further action is required.";
            break;
        }
    }

    const submit = (action: number) => {
        handleReport(action, responseId!, reportDetail, editedDockScore)
            .then(_ => {
                toast.success("Report Updated Successfully");
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        if (!reportDetail.report) {
            return <ErrorComponent />;
        } else {
            return (
                <Container sx={{mb: 10}}>
                    <Grid container spacing={2} sx={{mb: 5}}>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h4" sx={{mb: 6}}>
                                Reported Response
                            </Typography>
                            <VideoPlayer
                                options={videoOptions}
                            />
                        </Grid>
                        <Grid container size={{xs: 12, sm: 12, md: 6}}>
                            <Grid size={{xs: 6}}>
                                <Typography variant="h6">
                                    Reviewer Comments
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb: 3}}>
                                    {`${reportDetail.report.reduce((p, c) => +!c.isReportHandled + p, 0)} new report(s)`}
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => submit(0)}
                                    startIcon={<MarkEmailReadIcon />}
                                    disabled={!reportDetail.reportedAction || reportDetail.numberReports === reportDetail.numberHandledReports}
                                >
                                    Mark all Read
                                </Button>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <List sx={{overflowY: 'auto', maxHeight: 440}}>
                                    {reportDetail.report.map((c, i) =>
                                        <ReportCommentListItem report={c} key={i} />
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mb: 5}}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h5">
                                Actions
                            </Typography>
                            <Divider sx={{my: 3}} />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Paper elevation={1} sx={{p: 2}}>
                                <Box component="div" sx={{textAlign: "center"}}>
                                    <Typography variant="subtitle2" display="inline">
                                        {`Previous Action Taken:  `}
                                    </Typography>
                                    <Typography variant="subtitle2" display="inline" sx={{border: "solid 1px", p: 1}}>
                                        {previousActionText}
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle2" sx={{p: 3, textAlign: "center"}}>
                                    {reportText}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    {reportDetail.reportedAction === 3 ?
                        null :
                        <Grid container spacing={4}>
                            <Grid size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DoNotDisturbOnIcon />}
                                    fullWidth
                                    disabled={!reportDetail.canHandle || reportDetail.reportedAction === 3}
                                    onClick={() => submit(1)}
                                >
                                    Ignore
                                </Button>
                            </Grid>
                            <Grid container size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                                <Grid size={{xs: 12, sm: 12, md: 4}}>
                                    <TextField
                                        label="Penalty"
                                        id="reportedDockScore"
                                        size="small"
                                        type="number"
                                        color="warning"
                                        focused
                                        error={editedDockScore < 0.5 || editedDockScore > 9}
                                        fullWidth
                                        onChange={handleDockScoreChange}
                                        value={editedDockScore}
                                        slotProps={{
                                            htmlInput: {step: 0.5}
                                        }}
                                        disabled={!reportDetail.canHandle || reportDetail.reportedAction === 3}
                                    />
                                </Grid>
                                <Grid size={{xs: 12, sm: 12, md: 8}}>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        startIcon={<ArrowCircleDownIcon />}
                                        fullWidth
                                        disabled={!reportDetail.canHandle || reportDetail.reportedAction === 3}
                                        onClick={() => submit(2)}
                                    >
                                        Dock Score
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid size={{xs: 12, sm: 12, md: 4}} sx={{textAlign: "center"}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    startIcon={<CancelIcon />}
                                    disabled={!reportDetail.canHandle || reportDetail.reportedAction === 3}
                                    onClick={() => setEliminationModalOpen(true)}
                                >
                                    Eliminate
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    <EliminationModal
                        open={eliminationModalOpen}
                        setOpen={setEliminationModalOpen}
                        action={() => submit(3)} />
                </Container>
            )
        }
    }
}