import React from "react";
import {Avatar, Box, Card, CardContent, Typography, Grid2 as Grid, useMediaQuery} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PersonIcon from '@mui/icons-material/Person';
import {LeaderCardProps} from "../../data-types";

export function LeaderCard({place, user}: LeaderCardProps) {

    // leader card is drastically different if on a phone
    const isPhone = useMediaQuery("(max-width: 600px)");

    let color;
    switch (place) {
        case "1st":
            color = "#FFD700";
            break;
        case "2nd":
            color = "#c0c0c0";
            break;
        case "3rd":
            color = "#967444";
            break;
        default:
            color = "#FFFFFF";
    }

    if (isPhone) {
        return (
            <Grid container size={{xs: 12}} sx={{p: 1, border: "1px solid #ECECEC", bgcolor: "#F1F1F1"}} alignItems="center">
                <Grid size={{xs: 2}}>
                    <Avatar
                        src={user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ""}
                        sx={{width: 50, height: 50, border: `2px solid ${color}`}}
                        alt={`${user.username}'s Avatar`}
                    >
                        <PersonIcon titleAccess={`${user.username}'s avatar`} sx={{width: 42, height: 42}}/>
                    </Avatar>
                </Grid>
                <Grid size={{xs: 5}}>
                    <Typography variant="h6">
                        {user.username}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`${user.firstName} ${user.lastName}`}
                    </Typography>
                </Grid>
                <Grid size={{xs: 2}}>
                    <Box sx={{textAlign: 'center'}} component="div">
                        <Typography variant="body1" component="span">
                            {user.totalScore.toFixed(2)}
                        </Typography>
                        <Typography variant="subtitle2">
                            Total
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{xs: 3}}>
                    <Box sx={{textAlign: 'center'}} component="div">
                        <EmojiEventsIcon sx={{mx: 1, color: color}} />
                        <Typography variant="body1">
                            {place} Place
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Card raised={(place === "1st")} sx={(place === "1st") ? {py: 5, mt: 2, bgcolor: "#f5f5f5"} : {mt: 3}}>
                <CardContent>
                    <Box sx={{display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', mt: 3, mb: 3}} component="div">
                        <Avatar
                            src={user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ""}
                            sx={{width: 70, height: 70, mb: 2, border: `2px solid ${color}`}}
                            alt={`${user.username}'s Avatar`}
                        >
                            <PersonIcon titleAccess={`${user.username}'s avatar`} sx={{width: 42, height: 42}}/>
                        </Avatar>
                        <Box sx={{textAlign: "center"}} component="div">
                            <Typography variant="h4" component="h2">
                                {user.username}
                            </Typography>
                            <Typography variant="subtitle2" noWrap sx={{fontSize: 12}}>
                                {`${user.firstName} ${user.lastName}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid size={{xs: 6, md: 6}}>
                            <Box sx={{textAlign: 'center'}} component="div">
                                <Typography variant="h6" component="span">
                                    {user.totalScore.toFixed(2)}
                                </Typography>
                                <Typography variant="body1">
                                    Total
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{xs: 6, md: 6}}>
                            <Box sx={{textAlign: 'center'}} component="div">
                                <EmojiEventsIcon sx={{mx: 1, color: color}} fontSize="large" />
                                <Typography variant="h6">
                                    {place} Place
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}
