import React from "react";
import {Container, Grid2 as Grid, Typography, Paper} from "@mui/material";
import {VideoReviewContent, StandardAttribute} from "./VideoReviewConfig";
import {LoadingSpinner, ErrorComponent, VideoPlayer} from "../../components";
import {RequestStatus} from "../../data-types";
import {VideoReviewSection} from "./VideoReviewSection";

interface ContentSectionProps {
    promptToggle: boolean;
    contentRequestStatus: RequestStatus;
    content: VideoReviewContent[];
    handleScoreChange: (reviewId: number, attributeName: string, newScore: number) => void;
    handleCommentChange: (reviewId: number, comments: string) => void;
    handleSaveReview: (content: VideoReviewContent) => void;
    handleStartReport: (reviewId: number) => void;
    standardAttributes: StandardAttribute[];
    handleStandardAttributeChange: (reviewId: number, attribute: StandardAttribute) => void;
}
export function ContentSection({promptToggle, contentRequestStatus, content, handleScoreChange, handleCommentChange, handleSaveReview, handleStartReport, standardAttributes, handleStandardAttributeChange}: ContentSectionProps) {

    if (contentRequestStatus === "loading") {
        return <LoadingSpinner />
    } else if (contentRequestStatus === "error") {
        return <ErrorComponent />
    } else {

        // if we're in prompt view, get the video prompt from the first content object
        const promptVideo = (promptToggle) ? content[0].videoPrompt || null : null;

        return (
            <Container sx={{mb: 10}}>
                {promptToggle ?
                    <Grid container alignItems="center" sx={{mb: 5}} spacing={2}>
                        <Grid size={{xs: 3, sm: 4, md: 3}} sx={{textAlign: "center"}}>
                            <Typography variant="subtitle2">
                                Video Prompt
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 9, sm: 8, md: 3}}>
                            <Paper elevation={0} sx={{p: 2}}>
                                <VideoPlayer
                                    options={promptVideo}
                                    height={220}
                                />
                            </Paper>
                        </Grid>
                    </Grid> :
                    null
                }
                {content.map((c, i) =>
                    <VideoReviewSection
                        key={i}
                        content={c}
                        promptToggle={promptToggle}
                        handleScoreChange={handleScoreChange}
                        handleCommentChange={handleCommentChange}
                        handleSaveReview={handleSaveReview}
                        handleStartReport={handleStartReport}
                        standardAttributes={standardAttributes}
                        handleStandardAttributeChange={handleStandardAttributeChange}
                    />
                )}
            </Container>
        )
    }
}
