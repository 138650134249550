import React from "react";
import {Button} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

export function FeedbackButton() {

    const location = useLocation();

    return (
        <Button
            sx={{
                position: "fixed",
                top: 350,
                right: -25,
                height: 30,
                zIndex: 99,
                transform: "rotate(-90deg)"
            }}
            size="small"
            variant="contained"
            color="primary"
            component={Link}
            to="/feedback"
            state={{prevLocation: location.pathname}}
        >
            Feedback
        </Button>
    )
}