import React from "react";
import {SvgIcon} from "@mui/material";
import {LabeledIconProps} from "./LabeledIconProps";

export function PersonLabeledIcon({label, accessibilityText}: LabeledIconProps) {

    return (
        <SvgIcon
            fontSize="large"
            titleAccess={accessibilityText || `person-${label}`}
            aria-label={accessibilityText || `person-${label}`}
        >
            <path
                d="M 20.717 22.842 C 20.717 23.064 20.714 23.285 20.707 23.505 L 3.511 23.505 C 3.504 23.285 3.501 23.064 3.501 22.842 C 3.501 17.01 5.872 12.044 9.189 10.174 C 8.384 9.372 7.886 8.263 7.886 7.038 C 7.886 4.594 9.868 2.612 12.312 2.612 C 14.756 2.612 16.738 4.594 16.738 7.038 C 16.738 8.34 16.175 9.512 15.279 10.322 C 18.465 12.296 20.717 17.158 20.717 22.842 Z"
            />
            <text
                fill="white"
                x="50%"
                y="75%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="10"
                fontWeight="bold"
            >
                {label}
            </text>
        </SvgIcon>
    )
}