import React from "react";
import {Box, Button, Grid2 as Grid, Modal, Typography} from "@mui/material";
import { VideoModalProps } from "../../data-types";
import {VideoPlayer} from "../video-player";
import ErrorIcon from "@mui/icons-material/Error";
import {LoadingButton} from "@mui/lab";

export function SubmitResponseModal({open, setOpen, action, videoSource, handlePlayerReady, modalText, loading}: VideoModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "70%"},
        maxHeight: {xs: 500, sm: 600, md: 1000},
        overflowY: "auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    let videoOptions;

    if (videoSource) {
        videoOptions = {
            autoplay: false,
            controls: true,
            fill: true,
            userActions: {hotkeys: true},
            sources: [videoSource]
        }
    } else {
            videoOptions = null;
    }

    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 0, sm: 0, md: 2}} />
                    <Grid size={{xs: 12, sm: 12, md: 8}}>
                        <VideoPlayer
                            options={videoOptions}
                            handlePlayerReady={handlePlayerReady ?? ((_) => {})}
                        />
                    </Grid>
                    <Grid size={{xs: 0, sm: 0, md: 2}} />
                    <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                        <ErrorIcon fontSize="large" color="secondary" sx={{mr: 2}}/>
                        <Typography variant="h6">
                            Do you want to save this video?
                        </Typography>
                        {modalText ?
                            <Typography variant="subtitle2">
                                {modalText}
                            </Typography> :
                            null
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mt: 3}} alignItems="center" justifyContent="space-evenly">
                    <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setOpen(false)}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid size={{xs: 6}} sx={{textAlign: "center"}}>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                action();
                            }}
                            fullWidth
                            loading={loading}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
