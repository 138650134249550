import React from "react";
import {SxProps, Button, Modal, Box, Grid2 as Grid, Typography, Divider} from "@mui/material";
import {Link} from "react-router-dom";

interface GoToResponsesModalProps {
    competitionId: number | null;
    close: () => void;
}

export function GoToResponsesModal({competitionId, close}: GoToResponsesModalProps) {

    const sx: SxProps = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Modal
            open={competitionId !== null}
            onClose={close}
        >
            <Box sx={sx} component="div">
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6">
                            Event in Response Phase
                        </Typography>
                        <Divider sx={{}} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle2">
                            Would you like to start your responses now?
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                        <Button
                            variant="outlined"
                            fullWidth
                            color="secondary"
                            onClick={close}
                        >
                            Not Now
                        </Button>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}} sx={{textAlign: "center"}}>
                        <Button
                            component={Link}
                            fullWidth
                            to={`/video-response/${competitionId}`}
                            variant="contained"
                        >
                            Go to Responses
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}