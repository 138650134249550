import React from "react";
import {Box, Button, Grid2 as Grid, Modal} from "@mui/material";
import {VideoPlayer} from "../video-player";
import {ViewVideoModalProps} from "../../data-types";

export function ViewVideoModal({open, setOpen, videoOptions}: ViewVideoModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "70%"},
        maxHeight: {xs: 500, sm: 600, md: 1000},
        overflowY: "auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid size={{xs: 12, sm: 12, md: 8}}>
                        <VideoPlayer
                            options={videoOptions}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{mt: 3}} alignItems="center" justifyContent="space-evenly">
                    <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}