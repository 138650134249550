import { createTheme, responsiveFontSizes } from "@mui/material";

//  Standout Green:  #2B871D
//  CollegeNet Blue:  #1E51A6

declare module "@mui/material/styles" {
    interface Palette {
        darkPrimary: Palette["primary"];
    }

    interface PaletteOptions {
        darkPrimary?: Palette["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        darkPrimary: true;
    }
}

export const FocusRingTheme = responsiveFontSizes(createTheme({
    /*******************************************
         IF YOU WANT THEME TO MATCH DEVICE SETTINGS, UNCOMMENT COLORSCHEMES AND COMMENT OUT PALETTE
     ******************************************/

    // colorSchemes: {
    //     dark: {
    //         palette: {
    //             primary: {
    //                 main: "#28871D",
    //                 light: "#52874c",
    //                 dark: "#206418"
    //             },
    //             secondary: {
    //                 main: "#EB0052"
    //             },
    //             info: {
    //                 main: "#dddddd"
    //             },
    //             background: {
    //                 default: "#202020",
    //                 paper:  "#252525"
    //             },
    //             success:  { // null slider color
    //                 main: "#a3c49f"
    //             }
    //         }
    //     },
    //     light: {
    //         palette: {
    //             primary: {
    //                 main: "#28871D",
    //                 light: "#52874c",
    //                 dark: "#206418"
    //             },
    //             secondary: {
    //                 main: "#EB0052"
    //             },
    //             info: {
    //                 main: "#dddddd"
    //             },
    //             background: {
    //                 default: "#F7F7F7",
    //                 paper: "#FFFFFF"
    //             },
    //             success:  { // null slider color
    //                 main: "#a3c49f"
    //             }
    //         }
    //     }
    // },

    palette: {
        /***************************************
            IF YOU WANT ONLY DARK MODE, UNCOMMENT THE BELOW LINE
            IF YOU ONLY WANT LIGHT MODE, COMMENT OUT THE BELOW LINE
         **************************************/
        // mode: "dark",
        primary: {
            main: "#28871D",
            light: "#52874c",
            dark: "#206418"
        },
        darkPrimary: {
            main: "#206418",
            light: "#206418",
            dark: "#206418",
            contrastText: "#206418"
        },
        secondary: {
            main: "#EB0052"
        },
        info: {
            main: "#dddddd"
        },
        /***************************************
         IF YOU WANT ONLY DARK MODE, UNCOMMENT THE BACKGROUND OBJECT LINE BELOW AND COMMENT OUT
         THE BACKGROUND OBJECT BELOW IT
         **************************************/
        // background: {
        //     default: "#202020",
        //     paper:  "#252525"
        // },
        /***************************************
         IF YOU WANT ONLY LIGHT MODE, UNCOMMENT THE BACKGROUND OBJECT LINE BELOW AND COMMENT OUT
         THE BACKGROUND OBJECT ABOVE IT
         **************************************/
        background: {
            paper: "#F7F7F7",
            default: "#FFFFFF"
        },
        success:  { // null slider color
            main: "#a3c49f"
        }
    },

    typography: {
        "fontFamily": `"Helvetica Neue","Helvetica","Arial","sans-serif"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        button: {
            textTransform: 'none'
        }
    },

    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 12
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 16
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    "&:focus-visible": {
                        outline: "2px solid black",
                        outlineOffset: 2
                    },
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMultiSectionDigitalClockSection-root': {
                        width: 90,
                        '.MuiMenuItem-root': {
                            width: 70
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordWrap: "break-word"
                }
            }
        },
        MuiCardHeader : {
            styleOverrides: {
                root: {
                    "& .MuiCardHeader-content": {
                        overflow: "hidden"
                    }
                }
            }
        }
    }

}));