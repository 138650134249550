export const UrlConfig = {

    buildUrl: function(url: string): string {
        if (window.location.host.match(/localhost:\d{4}/)) {
            return url;
        }
        return this.getBaseUrl() + "/data" + url;
    },

    getBaseUrl: function ():string {
        return '/' + this.getInstanceId() + '/' + this.getApplicationName();
    },
    getInstanceId: function ():string | null {
        return this.urlRegex.getFirstPathParam(this.getPathName());
    },
    getApplicationName: function ():string | null {
        return this.urlRegex.getSecondPathParam(this.getPathName());
    },

    getPathName: function():string {
        return window.location.pathname;
    },

    getAppBaseUrl: function(): string {
        return window.location.origin + this.getBaseUrl();
    },

    getResourceUrl: function() {
        if (window.location.host === "localhost:3000") {
            return this.getBaseUrl();
        }
        return "/resources/" + this.getApplicationName();
    },

    getPaymentUrl: function() {
        return "/payments/s/";
    },

    urlRegex: {
        getFirstPathParam: function (url: string) {
            return this._get(url, 1);
        },
        getSecondPathParam: function (url: string) {
            return this._get(url, 2);
        },
        _get: function (url: string, index: number) {
            if (url == null) return null;
            // strip http:// and https:// prefix
            url = url.replace(/https?:\/\//i, '');
            return url.split('/')[index];
        }
    }
}