import React, {ChangeEvent, useState} from "react";
import {Stack, Button, Paper, Container, Grid2 as Grid, Typography, Pagination} from "@mui/material";
import {OpenCompetitionResult, RequestStatus, UserInvitationState, Invitation} from "../../data-types";
import {Link} from "react-router-dom";
import {LoadingSpinner, ErrorComponent, EnrollCard} from "../../components";

interface FindCompetitionsHomepageProps {
    requestStatus: RequestStatus;
    openCompetitions: OpenCompetitionResult[];
    invitations: UserInvitationState;
    forceXsBreakpoint: boolean;
    initEnroll: (competitionId: number) => void;
    reject: (competitionId: number) => void;
}

export function FindCompetitionsHomepage({requestStatus, openCompetitions, invitations, forceXsBreakpoint, initEnroll, reject}: FindCompetitionsHomepageProps) {
    const [page, setPage] = useState<number>(1);
    const availableToJoin: (OpenCompetitionResult | Invitation)[] = [...openCompetitions];

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const content = (o: OpenCompetitionResult[]) => {
        if (requestStatus === "loading") {
            return <LoadingSpinner />;
        } else if (requestStatus === "error") {
            return <ErrorComponent />;
        } else {
            if (availableToJoin.length === 0) {
                return (
                    <Grid container sx={{p: 2}}>
                        <Grid size={{xs: 12}}>
                            <Typography variant="subtitle2">
                                You currently have no events available to join.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            } else {
                return (
                    <EnrollCard
                        event={availableToJoin[page - 1]}
                        forceXsBreakpoint={forceXsBreakpoint}
                        initEnroll={initEnroll}
                        reject={reject}
                    />
                )
            }
        }
    }

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230}}>
            <Grid container spacing={2} alignItems="center" sx={{px: 2, pt: 2}}>
                <Grid size={{xs: 6}}>
                    <Typography variant="h4" color="primary">
                        Join an Event
                    </Typography>
                </Grid>
                <Grid size={{xs: 6}} sx={{textAlign: "right"}}>
                    <Button
                        color="darkPrimary"
                        component={Link}
                        to="/enroll"
                    >
                        View All Events
                    </Button>
                </Grid>
            </Grid>
            {content(openCompetitions)}
            {availableToJoin.length > 1 ?
                <Stack alignItems="center">
                    <Pagination page={page} count={availableToJoin.length} onChange={handleChange} />
                    <Typography variant="subtitle2" sx={{mt: 2}}>
                        {`Event:  ${page} of ${availableToJoin.length}`}
                    </Typography>
                </Stack> :
                null
            }
        </Paper>
    )
}