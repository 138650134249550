import React from "react";
import {Button, Grid2 as Grid, Typography, Box, Divider} from "@mui/material";
import {OpenCompetitionResult, Invitation} from "../../data-types";
import {subHours, parseISO, isAfter, formatDistance, format} from "date-fns";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {TypographyInlineLinks} from "../typography-inline-links";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";

interface EnrollCardProps {
    event: OpenCompetitionResult | Invitation;
    forceXsBreakpoint?: boolean;
    initEnroll?: (competitionId: number) => void;
    reject?: (competitionId: number) => void;
}

export function EnrollCard({event, forceXsBreakpoint, initEnroll, reject}: EnrollCardProps) {

    const now = new Date();
    const competitionName = ("name" in event) ? event.name : event.competitionName;
    const competitionDescription = ("description" in event) ? event.description : event.competitionDescription;
    let firstRoundStartDate: Date;
    let firstRoundEndDate: Date;
    let firstRoundStatus: string;
    let enrollUntil: string = "";
    let enrollmentWarning: boolean = false;
    let showEnrollButtons = ("name" in event && !event.isEnrolled) || ("competitionName" in event && event.status === "pending");
    const enrollmentUnavailable = event.firstRoundResponseEnd !== null &&
        isAfter(now, subHours(parseISO(event.firstRoundResponseEnd + "Z"), 3)) &&
        !!event.paymentParticipant;

    if (event.firstRoundStart !== null) {
        firstRoundStartDate = parseISO(event.firstRoundStart + "Z");
        // if first round start is after now, then tell users it will be available in N time.
        // Else they can respond now.
        firstRoundStatus = (isAfter(firstRoundStartDate, now)) ?
            `Enrollment available!  The event begins in ${formatDistance(firstRoundStartDate, now)}.` :
            `Enroll and record your responses now!`;
    } else {
        // if firstRoundStart is null, then indicate that users will receive alert when responses available
        firstRoundStatus = "Enrollment available!  We will notify you when the first round begins."
    }

    if (enrollmentUnavailable) {
        firstRoundStatus = "Enrollment unavailable";
    }

    if (event.firstRoundResponseEnd !== null) {
        firstRoundEndDate = parseISO(event.firstRoundResponseEnd + "Z");
        // if there is payment for this event, then users are not allowed to enroll 3 hours before
        // end of first round response start.  They should also be warned 24 hours before the end
        // of the first round response phase
        if (event.paymentParticipant) {
            enrollUntil = `Enroll until:  ${format(subHours(firstRoundEndDate, 3), "Pp")}`;
            if (isAfter(now, subHours(firstRoundEndDate, 3))) {
                enrollUntil = "";
            } else if (isAfter(now, subHours(firstRoundEndDate, 24))) {
                enrollmentWarning = true;
            }
        } else {
            enrollUntil = `Enroll until:  ${format(firstRoundEndDate, "Pp")}`;
        }
    }

    return (
        <Grid container size={{xs: 12}} sx={{p:3}} alignItems="center">
            <Grid container size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 8, md: 8}} alignItems="center" justifyContent="center" order={{xs: 1}} >
                <Grid size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 1, md: 1}} sx={{textAlign: {xs: "center", sm: forceXsBreakpoint ? "center" : "left"}}}>
                    <PlaylistAddCircleIcon fontSize="large" sx={{color: "#444444", mt: 1, mr: 1}} />
                </Grid>
                <Grid size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: forceXsBreakpoint ? "center" : "left"}}}>
                    <Typography variant="h6">
                        {competitionName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 8, md: 8}} alignItems="center" justifyContent={{xs: "center", sm: forceXsBreakpoint ? "center" : "start"}} order={{xs: 2, sm: 3, md: 3}} sx={{mt: 2}}>
                <Grid size={{xs: 0, sm: 1, md: 1}} />
                <Grid size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 11, md: 11}} sx={{textAlign: {xs: "center", sm: forceXsBreakpoint ? "center" : "left"}}}>
                    <Typography variant="subtitle2">
                        {competitionDescription}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 4, md: 4}} sx={{mt: 2}} spacing={1} justifyContent={{xs: "center", sm: forceXsBreakpoint ? "center" : "end"}}  order={{xs: 3, sm: 2}}>
                <Grid size={{xs: 12}}>
                    <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: forceXsBreakpoint ? "center" : "end"}}}>
                        <InfoIcon sx={{mr: 1}} />
                        <Typography variant="subtitle2">
                            {firstRoundStatus}
                        </Typography>
                    </Box>
                </Grid>
                <Grid size={{xs: 12}} sx={{textAlign: {xs: "center", sm: forceXsBreakpoint ? "center" : "right"}}}>
                    <Typography variant="subtitle2">
                        {enrollUntil}
                    </Typography>
                </Grid>
            </Grid>
            {("prizeDescription" in event) && event.prizeDescription ?
                <Grid container size={forceXsBreakpoint ? {xs: 12} : {xs: 12, sm: 12, md: 8}} sx={{mt: {xs: 3}}} alignItems="center" justifyContent={{xs: "center", sm: forceXsBreakpoint ? "center" : "start"}} order={{xs: 4}}>
                    <Grid size={{xs: 0, sm: 1}} />
                    <Grid size={{xs: 12, sm: 11}}>
                        <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: {xs: "center", sm: forceXsBreakpoint ? "center" : "start"}}}>
                            <WorkspacePremiumIcon color="primary" sx={{mr: 1}} />
                            <TypographyInlineLinks variant="subtitle2" text={event.prizeDescription} />
                        </Box>
                    </Grid>
                </Grid> :
                null
            }
            <Grid container size={{xs: 12}} order={{xs: 5}}>
                <Grid size={{xs: 12}}>
                    <Divider sx={{my: 3}} />
                </Grid>
            </Grid>
            <Grid container size={{xs: 12}} spacing={2} justifyContent="end" order={{xs: 6}}>
                {(("name" in event) && event.isEnrolled) ?
                    <Grid size={{xs: 12}} sx={{textAlign: "right"}}>
                        <Typography color="primary" variant="subtitle2">
                            Enrolled
                        </Typography>
                    </Grid> :
                    (("competitionName" in event) && event.status !== "pending") ?
                        <Grid size={{xs: 12}} sx={{textAlign: "right"}}>
                            <Typography variant="subtitle2" color={event.status === "accepted" ? "primary" : "secondary"}>
                                {event.status === "accepted" ? "Enrolled" : "Rejected"}
                            </Typography>
                        </Grid> :
                        null
                }
                {showEnrollButtons ?
                    <>
                        <Grid size={{xs: 12, sm: 4, md: 3}} sx={{textAlign: "right"}}>
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon/>}
                                fullWidth
                                onClick={() => initEnroll?.(event.competitionId)}
                            >
                                Enroll
                            </Button>
                        </Grid>
                        {!("name" in event) && reject ?
                            <Grid size={{xs: 12, sm: 4, md: 3}} sx={{textAlign: "right"}}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<CancelIcon />}
                                    onClick={() => reject(event.competitionId)}
                                >
                                    Decline
                                </Button>
                            </Grid> :
                            null
                        }
                    </> :
                    null
                }
            </Grid>
        </Grid>
    )
}