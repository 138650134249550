import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Box,
    Typography, Avatar,
} from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {RankingsTableProps} from "../../data-types";
import {RankIcon} from "../../icons";
import PersonIcon from "@mui/icons-material/Person";

export function RankingsTable({ranks, showTrend}: RankingsTableProps) {
    const trendIcon = function(trend: number) {
        if (trend > 0) {
            return <TrendingUpIcon color="primary" />
        } else if (trend < 0) {
            return <TrendingDownIcon color="secondary" />
        } else {
            return <TrendingFlatIcon />
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {ranks.map((c, i) => (
                        <TableRow key={i} sx={(c.eliminated) ? {color: "#444444", bgcolor: "#BBBBBB"} : {bgcolor: "#ECECEC", color: "#000000"} }>
                            <TableCell align="left">
                                <Avatar
                                    src={c.avatar ? `data:image/jpeg;base64,${c.avatar}` : ""}
                                    sx={{width: 50, height: 50}}
                                    alt={`${c.username}'s Avatar`}
                                >
                                    <PersonIcon titleAccess={`${c.username}'s avatar`} sx={{width: 42, height: 42}}/>
                                </Avatar>
                            </TableCell>
                            <TableCell align="left" sx={{color: "inherit", width: "40%"}}>
                                <Box sx={{maxWidth: 130}} component="div">
                                    <Typography variant="h6" noWrap>
                                        {c.username}
                                    </Typography>
                                    <Typography variant="body1" noWrap sx={{color: "#000000", fontSize: 12}}>
                                        {`${c.firstName} ${c.lastName}`}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="right" sx={{color: "inherit", fontSize: 24, width: "25%"}}>
                                <RankIcon rank={c.percentile} />
                            </TableCell>
                            <TableCell align="right" >
                                {showTrend ?
                                    trendIcon(c.rankTrend) :
                                    null
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
